import React, { useReducer, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../header/Header";
import Footer from "./../footer/Footer";
import Styles from "./home.module.css";
import Doctor from "./doctor/Doctor";
import Patient from "./patient/Patient";
import SelectPrint from "./SelectPrint";
import SelectData from "./selectData/SelectData";
import BrowseFile from "./BrowseFile";
import Print360 from "./Print360";
import Date from "./date/Date";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Loading from "../loading/loading";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import CustomToastify, { toastMes } from "../../globalConf/notification";
import {
  httpNonToken,
  endpoints,
  basicUrl,
} from "../../globalConf/http-non-token";
import { jsPDF } from "jspdf";
import { PDFDocument } from "pdf-lib";
import html2canvas from "html2canvas";
import download from "downloadjs";
import axios from "axios";

const Home = () => {
  let dataAuth = JSON.parse(localStorage.getItem("user"));
  const [success, setSuccess] = useState(true);
  const [open, setOpen] = useState(false);
  const [discount, setDiscount] = useState(0.0);
  const [servicePrice, setServicePrice] = useState();
  const [requestResult, setRequestResult] = useState({});
  const [loadingFlag, setLoadingFlag] = useState(true);
  const [check, setCheck] = useState(false);
  const [user, setUser] = useState({});
  const [id, setId] = useState();
  const [request, setRequest] = useReducer(
    (prev, next) => ({
      ...prev,
      ...next,
    }),
    {
      patientName: "Patient",
      doctorName: "Doctor",
      searchDoctor: "",
      patientPhone: "",
      serviceName: "",
      digitalServiceName: "",
      digitalFile: false,
      submit: false,
      doctorRegister: false,

      //
      statu: 2,
      patient: "",
      attach: "",
      descraption: "",
      service: "",
      digitalService: "",
      doctorActive: "",
      DICON_source: "",
      special_discount: 0.0,
      doctor: "",
      created_by: "",
    }
  );

  const navigate = useNavigate();

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setCheck(false);
    if (request.submit) {
      setDiscount("");
      setRequest({
        patientName: "Patient",
        doctorName: "Doctor",
        patientPhone: "",
        serviceName: "",
        digitalServiceName: "",
        digitalFile: false,
        submit: false,
        //
        doctorRegister: false,
        statu: 2,
        attach: "",
        doctorActive: "",
        patient: "",
        descraption: "",
        service: "",
        digitalService: "",
        DICON_source: "",
        doctor: "",
        special_discount: 0.0,
        created_by: JSON.parse(localStorage.getItem("user")).user.id,
      });
    }

    setRequestResult({});
  };

  useEffect(() => {
    let isMounted = true;
    (async () => {
      if (dataAuth) {
        try {
          let userRes;
          if (JSON.parse(localStorage.getItem("user")).user.id) {
            userRes = await httpNonToken.get(
              `${endpoints.user}${
                JSON.parse(localStorage.getItem("user")).user.id
              }`
            );
          }
          if (isMounted) {
            if (JSON.parse(localStorage.getItem("user")).user.id) {
              setUser(userRes.data);
            }
            setLoadingFlag(false);
          }
        } catch ({ response }) {
          setSuccess(false);
          toastMes.error(response.data.message);
        }
      } else {
        navigate("/login");
      }
    })();
    return () => {
      isMounted = false;
    };
  }, []);

  const handleChangeModal = async () => {
    if (
      !request.patient ||
      !request.doctor ||
      (!request.service && !request.digitalService) ||
      !request.DICON_source
    ) {
      setSuccess(false);
      toastMes.error("Please Fill All Field");
      return;
    } else {
      try {
        let service =
          request.digitalService && request.service
            ? [request.service, request.digitalService]
            : request.digitalService && !request.service
            ? [request.digitalService]
            : !request.digitalService && request.service
            ? [request.service]
            : null;
        let getPriceRes = await httpNonToken.post(`${endpoints.requestPrice}`, {
          patient: request.patient,
          service,
          branch: dataAuth.user.branch.id,
        });
        if (getPriceRes.status === 200) {
          setServicePrice(getPriceRes.data.amount);
          handleOpen();
        }
      } catch ({ response }) {
        for (const key in response.data) {
          setSuccess(false);
          toastMes.error(`${key}: ${response.data[key]}`);
        }
      }
    }
  };

  const pdfDownload = async (e) => {
    e.preventDefault();

    html2canvas(document.getElementById("service-pdf-view")).then(
      async function (canvas) {
        // Fetch JPEG image
        var dataURL = canvas.toDataURL("image/jpeg", 1.0);
        const jpgUrl = dataURL;
        const jpgImageBytes = await fetch(jpgUrl).then((res) =>
          res.arrayBuffer()
        );
        // Create a new PDFDocument
        const pdfDoc = await PDFDocument.create();
        // Embed the JPG image bytes and PNG image bytes
        const jpgImage = await pdfDoc.embedJpg(jpgImageBytes);
        // Get the width/height of the JPG image scaled down to 25% of its original size
        const jpgDims = jpgImage.scale(0.3);
        // Add a blank page to the document
        const page = pdfDoc.addPage();
        // Draw the JPG image in the center of the page
        page.drawImage(jpgImage, {
          x: page.getWidth() / 2 - jpgDims.width / 2,
          y: page.getHeight() - jpgDims.height,
          width: jpgDims.width,
          height: jpgDims.height,
        });
        // Serialize the PDFDocument to bytes (a Uint8Array)
        const pdfBytes = await pdfDoc.save();
        // Trigger the browser to download the PDF document
        download(
          pdfBytes,
          `${request.patientName} case.pdf`,
          "application/pdf"
        );
      }
    );
  };

  const handleDiscountChange = (e) => {
    setDiscount(e.target.value);
    setRequest({ special_discount: e.target.value });
  };

  const handleMakeRequest = async () => {
    if (
      !request.patient ||
      !request.doctor ||
      (!request.service && !request.digitalService) ||
      !request.DICON_source
    ) {
      setSuccess(false);
      toastMes.error("Please Fill All Field");
    } else {
      let data = new FormData();

      Object.keys(request).forEach((key) => data.append(key, request[key]));
      data.append(
        "created_by",
        JSON.parse(localStorage.getItem("user")).user.id
      );

      if (request.DICON_source === 2) {
        data.append("statu", 3);
      }

      try {
        let requestResponse = {};
        if (request.digitalService && request.service) {
          data.append("service", request.service);
          await axios.post(`${basicUrl}/request/create`, data, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
          data.append("service", request.digitalService);
          data.append("special_discount", 0.0);

          let requestRes = await axios.post(
            `${basicUrl}/request/create`,
            data,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
          requestResponse = requestRes.data;
        } else if (!request.digitalService && request.service) {
          data.append("service", request.service);
          let requestRes = await axios.post(
            `${basicUrl}/request/create`,
            data,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
          requestResponse = requestRes.data;
        } else if (request.digitalService && !request.service) {
          data.append("service", request.digitalService);
          let requestRes = await axios.post(
            `${basicUrl}/request/create`,
            data,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
          requestResponse = requestRes.data;
        }
        setRequest({ submit: true });
        setRequestResult(requestResponse);
        setSuccess(true);
        toastMes.success("Request Created Successfully");
      } catch ({ response }) {
        for (const key in response.data) {
          setSuccess(false);
          toastMes.error(`${key}: ${response.data[key]}`);
        }
      }
    }
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "1px solid lightgrey",
    boxShadow: 24,
    p: 3,
  };

  if (loadingFlag) {
    return <Loading />;
  }
  return (
    <>
      <Header />
      <Date branch={Object.keys(user).length ? user.branch.name : ""} />

      <div className="row p-2">
        <div className={`col-md-6`}>
          <div className="row p-2">
            <div className="col-6">
              <Patient request={request} setRequest={setRequest} />
            </div>
            <div className="col-6">
              <Doctor request={request} setRequest={setRequest} />
            </div>
            <div className="col-6">
              <SelectPrint request={request} setRequest={setRequest} />
            </div>
            <div className="col-6">
              <Print360 request={request} setRequest={setRequest} />
            </div>
          </div>
        </div>

        <div className={`col-md-6 mt-3`}>
          <div className="row">
            <div className="col-md-12">
              <SelectData request={request} setRequest={setRequest} />
            </div>
            <div className="col-md-12">
              <BrowseFile request={request} setRequest={setRequest} />
            </div>
          </div>
        </div>
      </div>

      <div className="text-end me-5">
        <Button variant="contained" size="medium" onClick={handleChangeModal}>
          Check Out
        </Button>
      </div>

      {/*  */}

      {/* <CustomToastify bgColor={success ? "green" : "red"} duration={8000} /> */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="check-out-box" sx={style}>
          <div id="modal-modal-title" variant="h5" component="h2">
            <img
              width="100px"
              className="d-block m-auto"
              src="/images/login/qubx-logo-black.png"
              alt="logo"
            />
          </div>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Patient Name: {request.patientName}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Patient Phone: {request.patientPhone}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Doctor Name: {request.doctorName}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2, mb: 1 }}>
            Printing:{" "}
            {request.digitalService && request.service
              ? "3D Printing - 360 Digital"
              : request.digitalService && !request.service
              ? "360 Digital"
              : !request.digitalService && request.service
              ? "3D Printing"
              : null}
          </Typography>
          <FormControlLabel
            control={
              <Checkbox value={check} onChange={(e) => setCheck(!check)} />
            }
            label="Patient Phone verified"
          />
          <div className="d-flex justify-content-between">
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Price
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              {servicePrice} EGP
            </Typography>
          </div>
          <div className="d-flex justify-content-between mb-3">
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Discount
            </Typography>
            <TextField
              disabled={request.submit}
              type="number"
              placeholder="0.00 EGP"
              id="standard-basic"
              size="small"
              variant="standard"
              value={discount}
              onChange={(e) => handleDiscountChange(e)}
            />
          </div>
          <Divider />
          <div className="d-flex justify-content-between my-2">
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Total Price
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              {isNaN(parseFloat(servicePrice) - parseFloat(discount))
                ? servicePrice
                : parseFloat(servicePrice) - parseFloat(discount)}{" "}
              EGP
            </Typography>
          </div>
          <div className="mt-3">
            {Object.keys(requestResult).length ? (
              <i
                onClick={pdfDownload}
                class="fa-solid fa-file-invoice mx-2 d-inline-block text-primary fa-2x"
                style={{ cursor: "pointer" }}
              ></i>
            ) : null}
          </div>
          <div className="d-flex flex-row-reverse mt-3">
            {request.submit ? (
              <Button variant="contained" color="error" onClick={handleClose}>
                Close
              </Button>
            ) : (
              <>
                <Button
                  variant="contained"
                  size="medium"
                  disabled={!check}
                  onClick={handleMakeRequest}
                >
                  Create request
                </Button>
                <Button
                  className="mx-2 bg-secondary text-white"
                  size="medium"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </>
            )}
          </div>
        </Box>
      </Modal>
      {Object.keys(requestResult).length ? (
        <div
          id="service-pdf-view"
          style={{
            position: "relative",
            minHeight: "2200px",
            borderBottom: "1px solid #365F91",
          }}
          className="p-2 w-100 mt-4"
        >
          <div
            style={{ borderTop: "1px solid #365F91" }}
            className={`d-flex justify-content-between my-5 py-3 px-2 ${Styles.header_section}`}
          >
            <div className="image-box w-25 h-25 mb-4">
              <img
                src="/images/header/logo-black.png"
                className="w-100 h-100"
                alt="logo"
              />
            </div>
            <h1 className="mt-3 bold" style={{ color: "#365F91" }}>
              INVOICE
            </h1>
          </div>
          <div className="d-flex justify-content-between my-3">
            <div className="">
              <h2>QUBX</h2>
              <h5>PERSONALIZED HEALTHCARE</h5>
            </div>
            <div>
              <div className="d-flex justify-content-start">
                <h4 className="mx-2">INVOICE </h4>
                <h4>#{requestResult.id}</h4>
              </div>
              {/* <div className="d-flex justify-content-start">
                <h4 className="mx-2">DATE:</h4>
                <p>{new Date().toLocaleString()}</p>
              </div> */}
            </div>
          </div>
          <div className="d-flex justify-content-between my-5">
            <div className="d-flex justify-content-start">
              <h4 className="mx-2">FROM:</h4>
              <h4>{Object.keys(user).length ? user.branch.name : null}</h4>
            </div>
            <div>
              <div className="d-flex justify-content-start">
                <h4 className="mx-2">To:</h4>
                <h4>{requestResult?.patient?.fullname}</h4>
              </div>
              <div className="d-flex justify-content-start">
                <h4 className="mx-2">Phone:</h4>
                <h4> {requestResult?.patient?.mobile}</h4>
              </div>
            </div>
          </div>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="caption table">
              <TableHead>
                <TableRow sx={{ backgroundColor: "#DAE4F0" }}>
                  <TableCell align="center">
                    <h5>QTY</h5>
                  </TableCell>
                  <TableCell align="center">
                    <h5>ITEM</h5>
                  </TableCell>
                  <TableCell align="center">
                    <h5>UNIT PRICE</h5>
                  </TableCell>
                  <TableCell align="center">
                    <h5>LINE TOTAL</h5>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="center">
                    <h6>1</h6>
                  </TableCell>

                  <TableCell align="center">
                    <h6>
                      {request.digitalService && request.service
                        ? "3D Printing - 360 Visual"
                        : request.digitalService && !request.service
                        ? "360 Digital"
                        : !request.digitalService && request.service
                        ? "3D Printing"
                        : null}
                    </h6>
                  </TableCell>
                  <TableCell align="center">
                    <h6>{servicePrice}</h6>
                  </TableCell>
                  <TableCell align="center">
                    <h6>EGP {servicePrice}</h6>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ border: "none" }}></TableCell>
                  <TableCell style={{ border: "none" }}></TableCell>
                  <TableCell align="center">
                    <h5>SubTotal</h5>
                  </TableCell>
                  <TableCell align="center">
                    <h6>EGP {servicePrice}</h6>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ border: "none" }}></TableCell>
                  <TableCell style={{ border: "none" }}></TableCell>
                  <TableCell align="center">
                    <h5>Discount</h5>
                  </TableCell>
                  <TableCell align="center">
                    <h6>{discount}</h6>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ border: "none" }}></TableCell>
                  <TableCell style={{ border: "none" }}></TableCell>
                  <TableCell align="center">
                    <h5>Total</h5>
                  </TableCell>
                  <TableCell align="center">
                    <h5>
                      EGP
                      {isNaN(parseFloat(servicePrice) - parseFloat(discount))
                        ? servicePrice
                        : parseFloat(servicePrice) - parseFloat(discount)}{" "}
                    </h5>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          <div
            className={`text-center w-100 py-5 px-2 ${Styles.invoice_footer}`}
            style={{ position: "absolute", left: "0px", bottom: "10px" }}
          >
            <h2 className="mb-3">Thank you for your trust</h2>
            <h5>info@qubx3d.com</h5>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Home;
