import React, { useEffect, useRef } from "react";
import Styles from "../home.module.css";

const CoverDoctor = ({ flag, request, showComponent, setShowComponent }) => {
  const coverRef = useRef(null);
  useEffect(() => {
    if (flag && !request.doctor && request.patient) {
      coverRef.current.click();
    }
  }, [flag, request.doctor, request.patient]);

  const getStyle = () => {
    let style = {};
    if (request.doctor) style.backgroundColor = "#DAE8FC";
    else style.backgroundColor = "white";
    return style;
  };

  return (
    <div
      style={getStyle()}
      className={`border rounded text-center w-100 py-3 ${Styles.doctor_section}`}
    >
      <img
        src="/images/global/medical_doctor.svg"
        className="my-3 w-40"
        alt="doctor"
        ref={coverRef}
        onClick={() => setShowComponent(!showComponent)}
      />
      <h6>{request.doctorName}</h6>
    </div>
  );
};

export default CoverDoctor;
