/* eslint-disable */
import React from "react";
import axios from "axios";

export const basicUrl = `https://py.qubx3d.com/api/v1`;
export const ipAddress = "http://188.166.169.201:8088/api/v1";

const instance = axios.create({
  baseURL: `${basicUrl}`,
});

instance.interceptors.request.use((config) => {
  return config;
});

export const httpNonToken = instance;
export const endpoints = {
  request: "/request",
  updateRequest: "/request/update",
  createRequest: "/request/create",
  requestStatus: "/status",
  smsTemplates: "/sms/template",
  qubxOperator: "/employeeProfile",
  note: "/note",
  createNote: "/note/create",
  sirvUpload: "/sirv/upload-file/",
  diconSource: "/diconSource",
  requestPrice: "/request/price/",
  updateUser: "/user/update",
  user: "/user/",
  changePassword: "/user/changePassword/",
  createDoctor: "/user/doctor-rc/create",
  notifications: "/notifications",
  updateNotification: "/notifications/update",
  updateGroupNotifications: "/notifications/read",
  updatePatient: "/patient/update",
  patient: "/patient",
  forgetPassword: "/user/forgot-password",
};
