// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/public/images/header/menu.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "nav {\n  height: 70px;\n}\n.header_qubx_logo__0UTxf {\n  width: 100px;\n}\n.header_notification_label__eSeJf,\n.header_profile_img__1DQI7 {\n  width: 45px;\n}\nheader_a {\n  color: #f3f2f2;\n}\n.header_header_info__ZaUfT {\n  display: flex;\n  right: 60px;\n  top: 19px;\n  flex-direction: row;\n  justify-content: flex-end;\n}\n/* button[type=\"button\"] {\n  background-color: #535780;\n  margin-top: 30px;\n} */\n.header_navbar-toggler-icon__F\\+gr- {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") !important;\n}\n@media only screen and (max-width: 991px) {\n  .header_nav_section__mvx14 {\n    height: auto !important;\n  }\n  .header_nav_box__NInAN {\n    justify-content: flex-start !important;\n  }\n  .header_header_info__ZaUfT {\n    right: 20px;\n    top: 5px;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/components/header/header.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;AACA;EACE,YAAY;AACd;AACA;;EAEE,WAAW;AACb;AACA;EACE,cAAc;AAChB;AACA;EACE,aAAa;EACb,WAAW;EACX,SAAS;EACT,mBAAmB;EACnB,yBAAyB;AAC3B;AACA;;;GAGG;AACH;EACE,oEAAkE;AACpE;AACA;EACE;IACE,uBAAuB;EACzB;EACA;IACE,sCAAsC;EACxC;EACA;IACE,WAAW;IACX,QAAQ;EACV;AACF","sourcesContent":["nav {\n  height: 70px;\n}\n.qubx_logo {\n  width: 100px;\n}\n.notification_label,\n.profile_img {\n  width: 45px;\n}\nheader_a {\n  color: #f3f2f2;\n}\n.header_info {\n  display: flex;\n  right: 60px;\n  top: 19px;\n  flex-direction: row;\n  justify-content: flex-end;\n}\n/* button[type=\"button\"] {\n  background-color: #535780;\n  margin-top: 30px;\n} */\n.navbar-toggler-icon {\n  background-image: url(\"/public/images/header/menu.svg\") !important;\n}\n@media only screen and (max-width: 991px) {\n  .nav_section {\n    height: auto !important;\n  }\n  .nav_box {\n    justify-content: flex-start !important;\n  }\n  .header_info {\n    right: 20px;\n    top: 5px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"qubx_logo": "header_qubx_logo__0UTxf",
	"notification_label": "header_notification_label__eSeJf",
	"profile_img": "header_profile_img__1DQI7",
	"header_info": "header_header_info__ZaUfT",
	"navbar-toggler-icon": "header_navbar-toggler-icon__F+gr-",
	"nav_section": "header_nav_section__mvx14",
	"nav_box": "header_nav_box__NInAN"
};
export default ___CSS_LOADER_EXPORT___;
