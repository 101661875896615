import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

//add Patient
export const addPatient = createAsyncThunk(
  "patient/create",
  async (patientData, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await axios.post(
        "https://py.qubx3d.com/api/v1/patient/create",
        patientData
      );
      return res.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const patientSlice = createSlice({
  name: "patient",
  initialState: { patients: [] },
  reducers: {},
  extraReducers: (builder) => {
    builder
      //add patient
      .addCase(addPatient.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(addPatient.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.patients.push(action.payload);
      })
      .addCase(addPatient.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default patientSlice.reducer;
