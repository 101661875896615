import React, { useState, useEffect, useRef } from "react";
import Styles from "../home.module.css";
import axios from "axios";
import WarningNotFound from "./WarningNotFound";
import TextField from "@mui/material/TextField";
import CustomToastify, { toastMes } from "../../../globalConf/notification";
import { httpNonToken, endpoints } from "../../../globalConf/http-non-token";
import Button from "@mui/material/Button";
import DoctorForm from "./doctorForm";

const SearchForm = ({
  setRequest,
  setFlag,
  request,
  flag,
  showComponent,
  setShowComponent,
  flip = false,
}) => {
  const [doctors, setDoctor] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [doctorId, setDoctorId] = useState("");
  const [showForm, setShowForm] = useState(true);
  const [fullname, setFullName] = useState();
  const [mobile, setMobile] = useState("");
  const [success, setSuccess] = useState(false);

  const formRef = useRef(null);
  const imgRef = useRef(null);
  useEffect(() => {
    if (flag && !doctorId) {
      formRef.current.focus();
    }
    // if (request.doctor) {
    //   (async () => {
    //     try {
    //       const userRes = await httpNonToken.get(
    //         `${endpoints.user}${request.doctor}`
    //       );
    //       setFullName(userRes.data.fullname);
    //       setMobile(userRes.data.mobile);
    //     } catch ({ response }) {
    //       setSuccess(false);
    //       toastMes.error(response.data.message);
    //     }
    //   })();
    // }
  }, [flag, doctorId]);

  useEffect(() => {
    (async () => {
      if (request.doctor) {
        try {
          const userRes = await httpNonToken.get(
            `${endpoints.user}${request.doctor}`
          );
          setFullName(userRes.data.fullname);
          setMobile(userRes.data.mobile);
        } catch ({ response }) {
          setSuccess(false);
          toastMes.error(response.data.message);
        }

        setShowComponent(!showComponent);
      }
    })();
  }, []);

  const getDoctor = async (e) => {
    setRequest({ searchDoctor: e.target.value });
    setFullName(e.target.value);
    setSearchTerm(e.target.value);
    const res = await axios.get(
      `https://py.qubx3d.com/api/v1/doctorProfile?user__fullname__icontains=${e.target.value}`
    );
    setDoctor(res.data.results);
  };

  const selectedDoctor = (e, active) => {
    setDoctorId(e.target.value);
    setRequest({
      doctor: e.target.value,
      doctorName: e.target.name,
      doctorActive: active,
    });
    setDoctor([]);
    setShowComponent(!showComponent);
  };

  const createDoctor = async () => {
    const fullnameRegax = /^[a-zA-Z0-9]+([_ -]?[a-zA-Z0-9])*$/;
    const mobileRegax = /^01[0-2,5]{1}[0-9]{8}$/;

    if (!fullnameRegax.test(fullname)) {
      setSuccess(false);
      toastMes.error(`Not Valid Doctor-Name`);
      return;
    }
    if (!mobileRegax.test(mobile)) {
      setSuccess(false);
      toastMes.error(`Not Valid Phone-Number`);
      return;
    }

    if (!fullname || !mobile) {
      setSuccess(false);
      toastMes.error("Please Fill All Field");
      return;
    } else {
      try {
        if (request.doctor) {
          const updateDoctorRes = await httpNonToken.put(
            `${endpoints.updateUser}/${request.doctor}/`,
            { mobile, fullname }
          );
          setSuccess(true);
          toastMes.success("Doctor Updated Successfully");
          setDoctorId(updateDoctorRes.data.id);
          setRequest({
            doctor: updateDoctorRes.data.id,
            doctorName: updateDoctorRes.data.fullname,
          });
          setDoctor([]);
          setShowComponent(!showComponent);
        } else {
          const createDoctorRes = await httpNonToken.post(
            `${endpoints.createDoctor}`,
            { user: { mobile, fullname } }
          );
          if (createDoctorRes.status === 201) {
            setSuccess(true);
            toastMes.success("Doctor Created Successfully");
            setDoctorId(createDoctorRes.data.user.id);
            setRequest({
              doctor: createDoctorRes.data.user.id,
              doctorName: createDoctorRes.data.user.fullname,
            });
            setDoctor([]);
            setShowComponent(!showComponent);
          } else {
            setSuccess(false);
            toastMes.success("something went wrong ...");
          }
        }
      } catch (error) {
        setSuccess(false);
        toastMes.error(error.message);
      }
    }
  };

  return (
    <div
      className={` ${
        !flip ? "mt-3" : ""
      }  bg-white border rounded overflow-auto ${Styles.doctor_section}`}
    >
      <div className="border-bottom">
        <img
          ref={imgRef}
          src="/images/global/medical_doctor.svg"
          className="my-1 w_15"
          alt="doctor"
          onClick={() => setShowComponent(!showComponent)}
        />
      </div>
      {showForm ? (
        <input
          type="search"
          placeholder="Search By Name"
          className="w_90 border rounded my-4 mb-1 mx-2 p-2 bg_light h_3 fn_xs"
          name="searchDoctor"
          ref={formRef}
          onChange={getDoctor}
        />
      ) : null}
      {doctors && doctors.length > 0 ? (
        <div className="w_90 ms-2 rounded mt-2 shadow ">
          {doctors.map((doctor, id) => (
            <div className="fn_xxs p-2 m-0 border-bottom h_4" key={doctor.id}>
              <div className="form-check d-flex flex-row-reverse justify-content-between">
                <input
                  style={{ zIndex: "1000" }}
                  className="form-check-input"
                  type="radio"
                  name={doctor.user.fullname}
                  id={doctor.user.id}
                  value={doctor.user.id}
                  onChange={(e) => selectedDoctor(e, doctor.user.is_active)}
                />
                <label
                  className="form-check-label d-block w-100"
                  htmlFor={doctor.user.id}
                >
                  {doctor.user.fullname}
                </label>
              </div>
              <span className={`d-block fn_xxs ms-3 ${Styles.patient_date}`}>
                {doctor.user.mobile}
              </span>
            </div>
          ))}
        </div>
      ) : showForm ? (
        <WarningNotFound
          request={request}
          showForm={showForm}
          setShowForm={setShowForm}
        />
      ) : (
        <DoctorForm
          flag={flag}
          setFlag={setFlag}
          imgRef={imgRef}
          showComponent={showComponent}
          setShowComponent={setShowComponent}
          request={request}
          setRequest={setRequest}
        />
        // <div className="doctor-form">
        //   <div className="mt-3">
        //     <TextField
        //       className="w-100"
        //       size="small"
        //       id="outlined-basic"
        //       label="Doctor Full Name"
        //       variant="outlined"
        //       value={fullname}
        //       onChange={(e) => setFullName(e.target.value)}
        //     />
        //   </div>
        //   <div className="mt-2">
        //     <TextField
        //       className="w-100"
        //       size="small"
        //       id="outlined-basic"
        //       label="Doctor Phone"
        //       variant="outlined"
        //       value={mobile}
        //       onChange={(e) => setMobile(e.target.value)}
        //     />
        //   </div>
        //   {request.doctorActive ? null : (
        //     <div className="d-flex justify-content-end mt-3">
        //       <Button onClick={createDoctor} variant="contained" size="small">
        //         {request.doctor ? "Update" : "Save"}
        //       </Button>
        //     </div>
        //   )}
        // </div>
      )}
      {/* <CustomToastify bgColor={success ? "green" : "red"} duration={8000} /> */}
    </div>
  );
};

export default SearchForm;
