import React, { useState, useEffect } from "react";

import Styles from "../home.module.css";
import axios from "axios";
import moment from "moment";
import WarningNotFound from "./WarningNotFound";

const SearchPatient = ({
  showComponent,
  setShowComponent,
  showForm,
  setShowForm,
  setRequest,
  request,
}) => {
  const [patients, setPatients] = useState([]); //data of patient
  const [searchTerm, setSearchTerm] = useState(""); // for search patient
  const [patientId, setPatientId] = useState(""); // patient name from radio  input

  useEffect(() => {
    if (request.patient) {
      setShowComponent(!showComponent);
    }
  }, []);

  const getPatients = async (e) => {
    setRequest({ patientPhone: e.target.value });
    setSearchTerm(e.target.value);
    const res = await axios.get(
      `https://py.qubx3d.com/api/v1/patient?mobile__contains=${e.target.value}`
    );
    setPatients(res.data.results);
  };

  const selectPatient = (e, phone) => {
    setPatientId(e.target.value);
    setRequest({
      patient: e.target.value,
      patientName: e.target.name,
      patientPhone: phone,
    });
    setPatients([]);
    setShowComponent(!showComponent);
  };

  return (
    <>
      <input
        type="search"
        placeholder="Search By Mobile"
        className="w_90 border rounded mt-4 mx-2 p-2 bg_light h_3 fn_xs"
        name="searchPatient"
        onChange={getPatients}
      />
      {patients && patients.length > 0 ? (
        <div className="w_90 ms-2 rounded mt-2 shadow ">
          {patients.map((patient, id) => (
            <div className="fn_xxs p-2 m-0 border-bottom h_4" key={patient.id}>
              <div
                htmlFor={patient.fullname}
                className="form-check d-flex flex-row-reverse justify-content-between"
              >
                <input
                  style={{ zIndex: "1000" }}
                  className="form-check-input"
                  type="radio"
                  name={patient.fullname}
                  id={patient.id}
                  value={patient.id}
                  onChange={(e) => selectPatient(e, patient.mobile)}
                />
                <label
                  className="form-check-label d-block w-100"
                  htmlFor={patient.id}
                >
                  {patient.fullname}
                </label>
              </div>
              <span className={`d-block fn_xxs ms-2 ${Styles.patient_date}`}>
                {patient.mobile} -{" "}
                {moment(patient.DOB, "YYYY-MM-DD").fromNow(true)}
              </span>
              {/* <span className={`d-block fn_xxs ms-2 ${Styles.patient_date}`}>
                {moment(patient.DOB, "YYYY-MM-DD").fromNow(true)}
              </span> */}
            </div>
          ))}
        </div>
      ) : (
        <WarningNotFound
          request={request}
          setRequest={setRequest}
          showForm={showForm}
          setShowForm={setShowForm}
        />
      )}
    </>
  );
};

export default SearchPatient;
