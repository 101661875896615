// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".changePasswaord_p_warning__hIW1N {\n  width: 95% !important;\n}\n.changePasswaord_img_warning__tWte9 {\n  float: left;\n  margin: 21px 5px 0 20px;\n}\n\n@media only screen and (max-width: 900px) {\n  .changePasswaord_content__PMjag {\n    width: 70% !important;\n  }\n}\n\n", "",{"version":3,"sources":["webpack://./src/components/changePassword/changePasswaord.module.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;AACvB;AACA;EACE,WAAW;EACX,uBAAuB;AACzB;;AAEA;EACE;IACE,qBAAqB;EACvB;AACF","sourcesContent":[".p_warning {\n  width: 95% !important;\n}\n.img_warning {\n  float: left;\n  margin: 21px 5px 0 20px;\n}\n\n@media only screen and (max-width: 900px) {\n  .content {\n    width: 70% !important;\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"p_warning": "changePasswaord_p_warning__hIW1N",
	"img_warning": "changePasswaord_img_warning__tWte9",
	"content": "changePasswaord_content__PMjag"
};
export default ___CSS_LOADER_EXPORT___;
