import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../header/Header";
import Footer from "../../footer/Footer";
import "./trackingRequest.css";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import {
  httpNonToken,
  endpoints,
  basicUrl,
} from "../../../globalConf/http-non-token";
import { httpToken, endpointsToken } from "../../../globalConf/http";
import CustomToastify, { toastMes } from "../../../globalConf/notification";
import Loading from "../../loading/loading";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Styles from "./requestTrackPrinting.module.css";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import axios from "axios";
// import JSZip from "jszip";
// import { saveAs } from "file-saver";
import { convertZipFile } from "../../../globalConf/zipping";
import "./360Digital.css";

const RequestTracking_360 = () => {
  let navigate = useNavigate();
  let data = JSON.parse(localStorage.getItem("user"));

  let { id } = useParams();
  const [allSMS, setAllSMS] = useState([]);
  const [url, setUrl] = useState("");
  const [request, setRequest] = useState();
  const [upload, setUpload] = useState(false);
  const [success, setSuccess] = useState(false);
  const [checked, setChecked] = useState(true);
  const [flag, setFlag] = useState(false);
  const [allOperator, setAllOperator] = useState([]);
  const [loadingFlag, setLoadingFlag] = useState(true);
  const [allQubxNotes, setAllQubxNotes] = useState([]);
  const [note, setNote] = useState("");
  const [uploadDocument, setUploadDocument] = useState(null);
  const [uploadFiles, setUploadFiles] = useState(null);

  const divRef = useRef("");

  useEffect(() => {
    if (!JSON.parse(localStorage.getItem("user"))) {
      navigate("/login");
    }
    let isMounted = true;
    setLoadingFlag(true);
    (async () => {
      if (data) {
        try {
          const requestRes = await httpToken.get(
            `${endpointsToken.request}/${id}`
          );

          await httpNonToken.post(`${endpoints.request}/${id}/readNotes`, {
            read: data.user.groups[0].toString(),
          });

          const qubxOperatorRes = await httpNonToken.get(
            `${endpoints.qubxOperator}`
          );
          const smsTemplatesRes = await httpNonToken.get(
            `${endpoints.smsTemplates}`
          );
          const qubxNoteRes = await httpNonToken.get(
            `${endpoints.note}?requests=${id}&category__id=${2}`
          );
          if (isMounted) {
            setRequest(requestRes.data);
            setChecked(requestRes.data.quality_assurence);
            setAllOperator(qubxOperatorRes.data.results);
            setAllSMS(smsTemplatesRes.data.results);
            setAllQubxNotes(qubxNoteRes.data);
            setLoadingFlag(false);
          }
        } catch ({ response }) {
          if (response.status === 401 && response.data.code) {
            localStorage.removeItem("user");
            navigate("/login");
            return;
          }
          setSuccess(false);
          toastMes.error(response.data.message);
        }
      } else {
        navigate("/login");
      }
    })();
    return () => {
      isMounted = false;
    };
  }, [id, flag]);

  if (loadingFlag) {
    return <Loading />;
  }

  const uploadFileAttach = async (e) => {
    let file = await convertZipFile(
      e.target.files,
      `request${request.id}`,
      `request${request.id}.zip`
    );
    let data = new FormData();
    data.append("attach", file);
    try {
      let updateRes = await axios.put(
        `${basicUrl}/request/update/${request.id}`,
        data,
        {
          onUploadProgress: (data) => {
            setUploadDocument(Math.round((data.loaded / data.total) * 100));
          },
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (updateRes.status === 200) {
        setRequest({
          ...request,
          ...updateRes.data,
        });
        setSuccess(true);
        toastMes.success(
          `Files in Request ${request.id} Uploaded Successfully`
        );
      }
    } catch ({ response }) {
      setSuccess(true);
      toastMes.error(response.data.message);
    }
    setUploadDocument(null);
  };

  const uploadFile = async (e) => {
    setUpload(true);
    let file = await convertZipFile(
      e.target.files,
      `request${request.id}`,
      `request${request.id}.zip`
    );
    let data = new FormData();
    data.append("DICON_File", file);
    try {
      let updateRes = await axios.put(
        `${basicUrl}/request/update/${request.id}`,
        data,
        {
          onUploadProgress: (data) => {
            setUploadFiles(Math.round((data.loaded / data.total) * 100));
          },
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (updateRes.status === 200) {
        const updateStatusRequestRes = await httpNonToken.put(
          `${endpoints.request}/update/${id}`,
          {
            statu: 3,
          }
        );
        setUpload(false);
        setRequest({
          ...request,
          ...updateRes.data,
          ...updateStatusRequestRes.data,
        });
        setSuccess(true);
        toastMes.success(
          `Files in Request ${request.id} Uploaded Successfully`
        );
      }
    } catch ({ response }) {
      setUpload(false);
      setSuccess(true);
      toastMes.error(response.data.message);
    }
    setUploadFiles(null);
  };

  const sendNote = async () => {
    if (!note) {
      setSuccess(false);
      toastMes.error(`Note Body shouldn't be empty`);
    } else {
      try {
        let sendNoteResponse = await httpNonToken.post(
          `${endpoints.createNote}`,
          {
            body: note,
            requests: id,
            category: 2,
            created_by: JSON.parse(localStorage.getItem("user")).user.id,
          }
        );
        if (sendNoteResponse.status === 201) {
          setSuccess(true);
          setAllQubxNotes([...allQubxNotes, sendNoteResponse.data]);
          setNote("");
        }
      } catch ({ response }) {
        setSuccess(false);
        toastMes.error(response.data.message);
      }
    }
  };

  const sendUrl = async () => {
    let regexp =
      /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
    if (!regexp.test(url)) {
      setSuccess(false);
      toastMes.error("Valid url is required");
      return;
    }
    try {
      const updateStatusRequestRes = await httpNonToken.put(
        `${endpoints.request}/update/${id}`,
        {
          DICON_URL: url,
          statu: 3,
        }
      );

      setRequest({
        ...request,
        ...updateStatusRequestRes.data,
      });
      setSuccess(true);
      toastMes.success(`URL Uploaded Successfully`);
    } catch ({ response }) {
      setSuccess(false);
      toastMes.error(response.data.message);
    }
  };

  const getStyle = (id) => {
    let style = {};
    style.borderRadius = "0px";
    style.textAlign = "center";
    style.padding = "5px";
    if (id === "In progress") {
      style.backgroundColor = "#77c3f6";
    } else if (id === "Delivered to Patient") {
      style.backgroundColor = "#A6efb5";
    } else if (id === "Returned") {
      style.backgroundColor = "#Ff4b4b";
    } else if (id === "Ready") {
      style.backgroundColor = "#Feff6b";
    } else if (id === "3D Printing") {
      style.backgroundColor = "#D5a4ff";
    } else if (id === "Cancelled") {
      style.backgroundColor = "#E6e6e6";
    } else if (id === "Pending") {
      style.backgroundColor = "#FE9F44";
    } else {
      style.backgroundColor = "lightgrey";
    }
    return style;
  };

  const getBgStyle = (data) => {
    let style = {};
    if (data) {
      style.backgroundColor = "#ddebff";
    } else {
      style.backgroundColor = "white";
    }
    return style;
  };

  return (
    <div className="bg_dark">
      <Header />
      <div className="bg_light p-2 py-4 rounded-top-20">
        <div className="m-4 overflow-hidden">
          <div className="overflow-hidden fn_main fn_sm d-flex align-items-center">
            <div className="float-start me-2">
              <button
                onClick={() => navigate(-1)}
                className="fw-bold border-0 f-primary bg-transparent btn-back"
              >
                <ArrowBackIosNewIcon />
                <h5 className="float-end text-primary">{request.id}</h5>
              </button>
            </div>
            <div className="float-start me-2">
              <h6 style={getStyle(request.statusView)}>{request.statusView}</h6>
            </div>
            <div className="mb-1">
              <span> {new Date(request.created_at).toLocaleString()}</span>
              <span> {request.created_by.fullname}, </span>
              <span className="text-uppercase">
                {" "}
                {data.user.branch ? data.user.branch.name : null}{" "}
              </span>
            </div>
          </div>
          <div className="row">
            <div className={`col-7 ms-3 ${Styles.info_boxes}`}>
              <div className="row">
                <div
                  className={`col text-center bg-white border border-primary me-3 ${Styles.info_box}`}
                >
                  <img
                    alt="patient card"
                    className="w-50 d-block mx-auto my-3"
                    src={"/images/global/nurse_patient.svg"}
                  />
                  <h6 className="mb-1">{request.patient.fullname}</h6>
                  <p className="mt-1" style={{ fontSize: "12px" }}>
                    {request.patient.mobile}
                  </p>
                </div>
                <div className="col text-center bg-white border border-primary me-3 info-box">
                  <img
                    alt="doctor card"
                    className="w-50 d-block mx-auto my-3"
                    src={"/images/global/medical_doctor.svg"}
                  />
                  <h6 className="mb-1">{request.doctor?.fullname}</h6>
                  <p className="mt-1" style={{ fontSize: "12px" }}>
                    {request.doctor?.mobile}
                  </p>
                </div>
                <div className="col text-center bg-white border border-primary me-3 info-box">
                  <img
                    alt="service card"
                    className="w-50 d-block mx-auto my-3"
                    src={"/images/global/360-printing.svg"}
                  />
                  <h6>{request.service.type.name}</h6>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-6">
                  <strong htmlFor="fileDownload" className="form-label d-block">
                    360 Digital Files
                  </strong>
                  <div>
                    <div className="mx-1  w-100">
                      {request.result_URL ? (
                        <p
                          className="border border-primary w-100 h-4 mt-3 p-2"
                          style={{
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                          }}
                        >
                          <a
                            className="text-decoration-none"
                            href={`https://view.qubx3d.com/${request.code}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {`https://view.qubx3d.com/${request.code}`}
                          </a>
                        </p>
                      ) : (
                        <input
                          type="text"
                          className="border border-secondary w-100 h-4 mt-2 p-2"
                          placeholder="www.qubx.com"
                        />
                      )}
                    </div>
                  </div>
                  <div className="mt-5">
                    <ul className="list-unstyled">
                      <li className="d-flex align-items-center justify-content-between mb-3">
                        <h5>Price</h5>
                        <span>{request.price} Egp</span>
                      </li>
                      <li className="d-flex align-items-center justify-content-between border-bottom mb-3">
                        <h5>Discount</h5>
                        <span>{request.special_discount} Egp</span>
                      </li>
                      <li className="d-flex align-items-center justify-content-between mb-3">
                        <h5>Total</h5>
                        <span>
                          {request.price - request.special_discount} Egp
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="col">
              <div
                style={getBgStyle(request.attach)}
                className="border-primary border_dash rounded mb-3"
              >
                <ul className="list-unstyled p-1">
                  {request.attach ? (
                    <li className="mb-2 d-flex justify-content-between px-3">
                      <a
                        id="fileDownload"
                        href={request.attach}
                        target="_blank"
                        download
                        className="mt-2"
                        rel="noreferrer"
                      >
                        <span htmlFor="fileDownload">
                          {request.patient.fullname} documents
                        </span>
                      </a>
                      <a
                        id="fileDownload"
                        href={request.attach}
                        target="_blank"
                        download
                        style={{ fontSize: "14px" }}
                        className="mt-2 text-secondary font-weight-light text-decoration-none"
                        rel="noreferrer"
                      >
                        <img
                          alt="file download"
                          src="/images/requestTracking/file-download.svg"
                          className="w-30 ms-3"
                        />
                      </a>
                    </li>
                  ) : (
                    <div className="d-flex justify-content-between mt-2 mx-3">
                      <p>{request.patient.fullname} documents not available</p>
                      <div style={{ position: "relative" }}>
                        <input
                          type="file"
                          id="digitalFile360"
                          multiple
                          onChange={uploadFileAttach}
                          style={{
                            position: "absolute",
                            left: "0px",
                            opacity: 0,
                          }}
                        />
                        <img
                          alt="file download"
                          src="/images/requestTracking/Upload.png"
                          width="50px"
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    </div>
                  )}
                </ul>
              </div>
              {uploadDocument ? (
                <div className="progress mb-3" style={{ height: "0.5rem" }}>
                  <div
                    className="progress-bar"
                    role="progressbar"
                    aria-valuenow={uploadDocument}
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style={{ width: `${uploadDocument}%` }}
                  >
                    <span className="sr-only">{uploadDocument}</span>
                  </div>
                </div>
              ) : null}

              <div
                style={getBgStyle(request.DICON_File)}
                className="border-primary border_dash rounded mb-3"
              >
                <ul className="list-unstyled p-1">
                  {request.DICON_source.id === 3 ? (
                    <li className="mb-1 d-flex justify-content-between px-3 mt-3">
                      {request.DICON_File ? (
                        <a
                          id="fileDownload"
                          href={request.DICON_File}
                          target="_blank"
                          download
                          style={{ fontSize: "14px" }}
                          className="mt-2 text-secondary font-weight-light text-decoration-none"
                          rel="noreferrer"
                        >
                          <span htmlFor="fileDownload">
                            {request.patient.fullname} DICOM Files
                          </span>
                        </a>
                      ) : (
                        <span>DICOM Files Not available</span>
                      )}
                      {request.DICON_File ? (
                        <a
                          id="fileDownload"
                          href={request.DICON_File}
                          target="_blank"
                          download
                          rel="noreferrer"
                        >
                          <img
                            alt="file download"
                            src="/images/requestTracking/file-download.svg"
                            className="w-30 ms-3 "
                          />
                        </a>
                      ) : (
                        <div style={{ position: "relative" }}>
                          {upload ? (
                            <img
                              src="/images/load.gif"
                              alt="upload"
                              width="50px"
                            />
                          ) : null}
                          <input
                            type="file"
                            id="digitalFile360"
                            multiple
                            onChange={uploadFile}
                            style={{
                              position: "absolute",
                              left: "0px",
                              opacity: 0,
                            }}
                          />
                          <img
                            alt="file download"
                            src="/images/requestTracking/Upload.png"
                            width="50px"
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                      )}
                    </li>
                  ) : null}
                  {request.DICON_source.id === 4 ? (
                    <li className="mb-1 d-flex justify-content-between px-3 mt-3">
                      {request.DICON_URL ? (
                        <a
                          id="fileDownload"
                          href={request.DICON_URL}
                          target="_blank"
                          download
                          style={{ fontSize: "14px" }}
                          className="mt-2 text-secondary font-weight-light text-decoration-none"
                          rel="noreferrer"
                        >
                          <span htmlFor="fileDownload">
                            {request.patient.fullname} Dicon URL
                          </span>
                        </a>
                      ) : (
                        <TextField
                          label="URL"
                          id="outlined-size-small"
                          size="small"
                          value={url}
                          onChange={(e) => setUrl(e.target.value)}
                        />
                      )}
                      {request.DICON_URL ? (
                        <a
                          id="fileDownload"
                          href={request.DICON_File}
                          target="_blank"
                          download
                          rel="noreferrer"
                        >
                          <img
                            alt="file download"
                            src="/images/requestTracking/file-download.svg"
                            className="w-30 ms-3 "
                          />
                        </a>
                      ) : (
                        <div style={{ position: "relative" }}>
                          <Button variant="contained" onClick={sendUrl}>
                            Send
                          </Button>
                        </div>
                      )}
                    </li>
                  ) : null}
                </ul>
              </div>
              {uploadFiles ? (
                <div
                  className="progress mt-2 mb-2"
                  style={{ height: "0.5rem" }}
                >
                  <div
                    className="progress-bar"
                    role="progressbar"
                    aria-valuenow={uploadFiles}
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style={{ width: `${uploadFiles}%` }}
                  >
                    <span className="sr-only">{uploadFiles}</span>
                  </div>
                </div>
              ) : null}

              {request.descraption ? (
                <div>
                  <p>
                    <b>Case Description: </b> {request.descraption}
                  </p>
                </div>
              ) : null}

              <div className="bg-wite">
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="qubx-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#qubx"
                      type="button"
                      role="tab"
                      aria-controls="qubx"
                      aria-selected="true"
                    >
                      Qubx
                    </button>
                  </li>
                </ul>
                <div
                  className="tab-content bg-white overflow-scroll"
                  id="myTabContent"
                >
                  <div
                    className="tab-pane fade show active"
                    id="qubx"
                    role="tabpanel"
                    aria-labelledby="qubx-tab"
                    style={{ maxHeight: "250px" }}
                  >
                    {allQubxNotes.length > 0 ? (
                      allQubxNotes.map((note) => {
                        return (
                          <div key={note.id}>
                            <div className="box p-2">
                              <div
                                className="info-msg me-3"
                                id={
                                  note.created_by.groups.some((elem) =>
                                    data.user.groups.includes(elem)
                                  )
                                    ? "sender"
                                    : ""
                                }
                              >
                                <span className="time d-block fn_sm f-main">
                                  {note.created_by.fullname} -{" "}
                                  {new Date(note.created_at).toLocaleString()}
                                </span>
                              </div>
                              <div className="content-msg p-2 mx-2 rounded bg_light fn_sm">
                                {note.body}
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <p className="lean text-center mt-4">
                        No Notes in This Request
                      </p>
                    )}
                    <div
                      ref={divRef}
                      className="p-2 d-flex justify-content-between text-center mx-2"
                    >
                      <textarea
                        className="p-2 border border-secondry  rounded w-75"
                        onChange={(e) => setNote(e.target.value)}
                        value={note}
                      ></textarea>
                      <Button variant="contained" onClick={sendNote}>
                        Send
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <CustomToastify bgColor={success ? "green" : "red"} duration={false} /> */}
    </div>
  );
};

export default RequestTracking_360;
