import React, { useState } from "react";

const WarningNotFound = ({ request, showForm, setShowForm }) => {
  return (
    <div className="text-center">
      <p className="mb-1" style={{ fontSize: "11px" }}>
        Name is not found, please add a new doctor
      </p>
      <button
        className="btn btn-primary py-1 px-3"
        style={{ fontSize: "11px" }}
        onClick={() => setShowForm(!showForm)}
      >
        {request.doctor ? "Update Doctor" : "Add Doctor"}
      </button>
    </div>
  );
};

export default WarningNotFound;
