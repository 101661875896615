import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../header/Header";
import Footer from "../../footer/Footer";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import Styles from "./requestTrackPrinting.module.css";
import { convertZipFile } from "../../../globalConf/zipping";
import "./trackingRequest.css";
import {
  httpNonToken,
  endpoints,
  basicUrl,
} from "../../../globalConf/http-non-token";
import { httpToken, endpointsToken } from "../../../globalConf/http";
import CustomToastify, { toastMes } from "../../../globalConf/notification";
import Loading from "../../loading/loading";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import axios from "axios";
// import JSZip from "jszip";
// import { saveAs } from "file-saver";
import "./360Digital.css";

const RequestTracking_3D = () => {
  let navigate = useNavigate();
  let data = JSON.parse(localStorage.getItem("user"));

  let { id } = useParams();

  const [allSMS, setAllSMS] = useState([]);
  const [url, setUrl] = useState("");
  const [upload, setUpload] = useState(false);
  const [request, setRequest] = useState();
  const [success, setSuccess] = useState(false);
  const [checked, setChecked] = useState(true);
  const [allOperator, setAllOperator] = useState([]);
  const [loadingFlag, setLoadingFlag] = useState(true);
  const [flag, setFlag] = useState(true);
  const [allQubxNotes, setAllQubxNotes] = useState([]);
  const [note, setNote] = useState("");
  const [courier, setCourier] = useState("");
  const [printingCenter, setPrintingCenter] = useState("");
  const [printingReceivedChecked, setPrintingReceivedChecked] = useState(false);
  const [sMSSentChecked, setSMSSentChecked] = useState(false);
  const [uploadDocument, setUploadDocument] = useState(null);
  const [uploadFiles, setUploadFiles] = useState(null);

  useEffect(() => {
    if (!JSON.parse(localStorage.getItem("user"))) {
      navigate("/login");
    }
    let isMounted = true;
    setLoadingFlag(true);
    (async () => {
      if (data) {
        try {
          const requestRes = await httpToken.get(
            `${endpointsToken.request}/${id}`
          );

          await httpNonToken.post(`${endpoints.request}/${id}/readNotes`, {
            read: data.user.groups[0].toString(),
          });

          const qubxOperatorRes = await httpNonToken.get(
            `${endpoints.qubxOperator}?page_size=1000`
          );
          const smsTemplatesRes = await httpNonToken.get(
            `${endpoints.smsTemplates}`
          );
          const qubxNoteRes = await httpNonToken.get(
            `${endpoints.note}?requests=${id}&category__id=${2}`
          );

          if (isMounted) {
            setPrintingReceivedChecked(
              [12, 13].includes(requestRes.data.statu.id)
            );
            setSMSSentChecked(requestRes.data.statu.id === 13 ? true : false);
            setRequest(requestRes.data);
            setChecked(requestRes.data.quality_assurence);
            setAllOperator(qubxOperatorRes.data.results);
            setAllSMS(smsTemplatesRes.data.results);
            setAllQubxNotes(qubxNoteRes.data);
            setLoadingFlag(false);
          }
        } catch ({ response }) {
          if (response.status === 401 && response.data.code) {
            localStorage.removeItem("user");
            navigate("/login");
            return;
          }
          setSuccess(false);
          toastMes.error(response.data.message);
        }
      } else {
        navigate("/login");
      }
    })();
    return () => {
      isMounted = false;
    };
  }, [flag]);

  if (loadingFlag) {
    return <Loading />;
  }

  const handleCourierChange = (event) => {
    setCourier(event.target.value);
  };

  const handlePrintingCenterChange = (event) => {
    setPrintingCenter(event.target.value);
  };

  const uploadFileAttach = async (e) => {
    let file = await convertZipFile(
      e.target.files,
      `request${request.id}`,
      `request${request.id}.zip`
    );
    let data = new FormData();
    data.append("attach", file);
    try {
      let updateRes = await axios.put(
        `${basicUrl}/request/update/${request.id}`,
        data,
        {
          onUploadProgress: (data) => {
            setUploadDocument(Math.round((data.loaded / data.total) * 100));
          },
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (updateRes.status === 200) {
        setRequest({
          ...request,
          ...updateRes.data,
        });
        setSuccess(true);
        toastMes.success(
          `Files in Request ${request.id} Uploaded Successfully`
        );
      }
    } catch ({ response }) {
      setSuccess(true);
      toastMes.error(response.data.message);
    }
    setUploadDocument(null);
  };

  const uploadFile = async (e) => {
    setUpload(true);
    let file = await convertZipFile(
      e.target.files,
      `request${request.id}`,
      `request${request.id}.zip`
    );
    let data = new FormData();
    data.append("DICON_File", file);
    try {
      let updateRes = await axios.put(
        `${basicUrl}/request/update/${request.id}`,
        data,
        {
          onUploadProgress: (data) => {
            setUploadFiles(Math.round((data.loaded / data.total) * 100));
          },
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (updateRes.status === 200) {
        const updateStatusRequestRes = await httpNonToken.put(
          `${endpoints.request}/update/${id}`,
          {
            statu: 3,
          }
        );
        setUpload(false);
        setRequest({
          ...request,
          ...updateRes.data,
          ...updateStatusRequestRes.data,
        });
        setSuccess(true);
        toastMes.success(
          `Files in Request ${request.id} Uploaded Successfully`
        );
      }
    } catch ({ response }) {
      setUpload(false);
      setSuccess(true);
      toastMes.error(response.data.message);
    }
    setUploadFiles(null);
  };

  const sendNote = async () => {
    if (!note) {
      setSuccess(false);
      toastMes.error(`Note Body shouldn't be empty`);
    } else {
      try {
        let sendNoteResponse = await httpNonToken.post(
          `${endpoints.createNote}`,
          {
            body: note,
            requests: id,
            category: 2,
            created_by: data.user.id,
          }
        );
        if (sendNoteResponse.status === 201) {
          setSuccess(true);
          setAllQubxNotes([...allQubxNotes, sendNoteResponse.data]);
          setNote("");
          // setFlag(!flag);
        }
      } catch ({ response }) {
        setSuccess(false);
        toastMes.error(response.data.message);
      }
    }
  };

  const sendUrl = async () => {
    let regexp =
      /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
    if (!regexp.test(url)) {
      setSuccess(false);
      toastMes.error("Valid url is required");
      return;
    }
    try {
      const updateStatusRequestRes = await httpNonToken.put(
        `${endpoints.request}/update/${id}`,
        {
          DICON_URL: url,
          statu: 3,
        }
      );

      setRequest({
        ...request,
        ...updateStatusRequestRes.data,
      });
      setSuccess(true);
      toastMes.success(`URL Uploaded Successfully`);
    } catch ({ response }) {
      setSuccess(false);
      toastMes.error(response.data.message);
    }
  };

  const handleChangePrintingReceived = async (e) => {
    try {
      const updateReq = await httpNonToken.put(
        `${endpoints.updateRequest}/${id}`,
        {
          statu: 12,
        }
      );
      setFlag(!flag);
      setSuccess(true);
      toastMes.success("Request Updates Successfully");
    } catch ({ response }) {
      setSuccess(false);
      toastMes.error(response.data.message);
    }
    // setPrintingReceivedChecked(!checked);
  };

  const handleChangeSMSSent = async (e) => {
    try {
      const updateReq = await httpNonToken.put(
        `${endpoints.updateRequest}/${id}`,
        {
          statu: 13,
        }
      );
      setFlag(!flag);
      setSuccess(true);
      toastMes.success("Request Updates Successfully");
    } catch ({ response }) {
      setSuccess(false);
      toastMes.error(response.data.message);
    }
  };
  const getStyle = (id) => {
    let style = {};
    style.borderRadius = "0px";
    style.textAlign = "center";
    style.padding = "5px";
    if (id === "In progress") {
      style.backgroundColor = "#77c3f6";
    } else if (id === "Delivered to Patient") {
      style.backgroundColor = "#A6efb5";
    } else if (id === "Returned") {
      style.backgroundColor = "#Ff4b4b";
    } else if (id === "Ready") {
      style.backgroundColor = "#Feff6b";
    } else if (id === "3D Printing") {
      style.backgroundColor = "#D5a4ff";
    } else if (id === "Cancelled") {
      style.backgroundColor = "#E6e6e6";
    } else if (id === "Pending") {
      style.backgroundColor = "#FE9F44";
    } else {
      style.backgroundColor = "lightgrey";
    }
    return style;
  };

  const getBgStyle = (data) => {
    let style = {};
    if (data) {
      style.backgroundColor = "#ddebff";
    } else {
      style.backgroundColor = "white";
    }
    return style;
  };

  return (
    <>
      <div className="bg_dark">
        <Header />
        <div className="bg_light p-2 py-4 rounded-top-20">
          <div className="m-4 overflow-hidden">
            <div className="overflow-hidden fn_main fn_sm d-flex align-items-center">
              <div className="float-start me-2">
                <button
                  onClick={() => navigate(-1)}
                  className="fw-bold border-0 f-primary bg-transparent btn-back"
                >
                  <ArrowBackIosNewIcon />
                  <h5 className="float-end text-primary">{request.id}</h5>{" "}
                </button>
              </div>
              <div className="float-start me-2">
                <h6 style={getStyle(request.statusView)}>
                  {request.statusView}
                </h6>
              </div>
              <div className="mb-1">
                <span> {new Date(request.created_at).toLocaleString()}</span>
                <span> {request.created_by.fullname}, </span>
                <span className="text-uppercase">
                  {" "}
                  {data.user ? data.user.branch.name : null}{" "}
                </span>
              </div>
            </div>
            <div className="row">
              <div className={`col-7 ms-3 ${Styles.info_boxes}`}>
                <div className="row">
                  <div
                    className={`col text-center bg-white border border-primary me-3 ${Styles.info_box}`}
                  >
                    <img
                      alt="patient card"
                      className="w-50 d-block mx-auto my-3"
                      src={"/images/global/nurse_patient.svg"}
                    />
                    <h6 className="mb-1">{request.patient.fullname}</h6>
                    <p className="mt-1" style={{ fontSize: "12px" }}>
                      {request.patient.mobile}
                    </p>
                  </div>
                  <div className="col text-center bg-white border border-primary me-3 info-box">
                    <img
                      alt="doctor card"
                      className="w-50 d-block mx-auto my-3"
                      src={"/images/global/medical_doctor.svg"}
                    />
                    <h6 className="mb-1">{request.doctor?.fullname}</h6>
                    <p className="mt-1" style={{ fontSize: "12px" }}>
                      {request.doctor?.mobile}
                    </p>
                  </div>
                  <div className="col text-center bg-white border border-primary me-3 info-box">
                    <img
                      alt="service card"
                      className="w-50 d-block mx-auto my-3"
                      src={"/images/global/3d-printing.svg"}
                    />
                    <h6>{request.service.type.name}</h6>
                    <p className="mt-1" style={{ fontSize: "12px" }}>
                      ( {request.service.name} )
                    </p>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className={`col-6 ${Styles.input_boxes}`}>
                    {/* <Box sx={{ minWidth: 200 }}>
                      <FormControl className="w-100">
                        <InputLabel id="demo-simple-select-label">
                          Qubx Operator
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={request.assign_to ? request.assign_to.id : ""}
                          label="Status"
                          name="operator"
                          disabled={true}
                        >
                          {allOperator.map((operator, index) => {
                            return (
                              <MenuItem key={index} value={operator.id}>
                                {operator.user.fullname}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Box>
                    <div className="mt-3">
                      <Box sx={{ minWidth: 200 }}>
                        <FormControl className="w-100">
                          <InputLabel id="demo-simple-select-label">
                            SMS
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={""}
                            label="SMS"
                            name="sms"
                            disabled={true}
                          >
                            {allSMS.map((sms, index) => {
                              return (
                                <MenuItem key={index} value={sms.id}>
                                  {sms.sms_type.name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Box>
                    </div>
                    <div className="mt-3 d-flex justify-content-start">
                      <div className="mx-2">
                        <img
                          alt="local-shipping"
                          src="/images/requestTracking/local-shipping.svg"
                          className="p-2 w-100 h-75 mx-2 bg-white border border-primary"
                          data-bs-toggle="modal"
                          data-bs-target="#shippingModal"
                        />
                      </div>
                    </div> */}
                    <div className="form-check">
                      <FormGroup sx={{ marginTop: "25px" }}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={checked}
                              disabled={true}
                              inputProps={{ "aria-label": "controlled" }}
                            />
                          }
                          label="Quality Assurance"
                        />
                      </FormGroup>
                    </div>
                    <div className="form-check mt-1">
                      <FormGroup sx={{ marginTop: "25px" }}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={handleChangePrintingReceived}
                              checked={printingReceivedChecked}
                              disabled={![8, 9, 10].includes(request.statu.id)}
                              inputProps={{ "aria-label": "controlled" }}
                            />
                          }
                          label="3D Printing Received"
                        />
                      </FormGroup>
                    </div>
                    <div className="form-check mt-1">
                      <FormGroup sx={{ marginTop: "25px" }}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={handleChangeSMSSent}
                              checked={sMSSentChecked}
                              disabled={request.statu.id !== 12}
                              inputProps={{ "aria-label": "controlled" }}
                            />
                          }
                          label="Delivered To Patient"
                        />
                      </FormGroup>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="">
                      <h5 htmlFor="fileDownload" className="form-label d-block">
                        360 visual URL
                      </h5>
                      <div></div>
                      <div className="mx-1  w-100">
                        {request.result_URL ? (
                          <p
                            className="border border-primary w-100 h-4 mt-3 p-2"
                            style={{
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                            }}
                          >
                            <a
                              className="text-decoration-none"
                              href={`https://view.qubx3d.com/${request.code}`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {/* {request.result_URL} */}
                              {`https://view.qubx3d.com/${request.code}`}
                            </a>
                          </p>
                        ) : (
                          <input
                            type="text"
                            className="border border-secondary w-100 h-4 mt-2 p-2"
                            placeholder="www.qubx.com"
                          />
                        )}
                      </div>
                    </div>
                    <div className="mt-5">
                      <ul className="list-unstyled">
                        <li className="d-flex align-items-center justify-content-between mb-3">
                          <h5>Price</h5>
                          <span>{request.price} Egp</span>
                        </li>
                        <li className="d-flex align-items-center justify-content-between border-bottom mb-3">
                          <h5>Discount</h5>
                          <span>{request.special_discount} Egp</span>
                        </li>
                        <li className="d-flex align-items-center justify-content-between mb-3">
                          <h5>Total</h5>
                          <span>
                            {request.price - request.special_discount} Egp
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col">
                <div
                  style={getBgStyle(request.attach)}
                  className="border-primary border_dash rounded mb-3"
                >
                  <ul className="list-unstyled p-1">
                    {request.attach ? (
                      <li className="mb-2 d-flex justify-content-between px-3">
                        <a
                          id="fileDownload"
                          href={request.attach}
                          target="_blank"
                          download
                          className="mt-2"
                          rel="noreferrer"
                        >
                          <span htmlFor="fileDownload">
                            {request.patient.fullname} documents
                          </span>
                        </a>
                        <a
                          id="fileDownload"
                          href={request.attach}
                          target="_blank"
                          download
                          style={{ fontSize: "14px" }}
                          className="mt-2 text-secondary font-weight-light text-decoration-none"
                          rel="noreferrer"
                        >
                          <img
                            alt="file download"
                            src="/images/requestTracking/file-download.svg"
                            className="w-30 ms-3"
                          />
                        </a>
                      </li>
                    ) : (
                      <div className="d-flex justify-content-between mt-2 mx-3">
                        <p>
                          {request.patient.fullname} documents not available
                        </p>
                        <div style={{ position: "relative" }}>
                          <input
                            type="file"
                            id="digitalFile360"
                            multiple
                            onChange={uploadFileAttach}
                            style={{
                              position: "absolute",
                              left: "0px",
                              opacity: 0,
                            }}
                          />
                          <img
                            alt="file download"
                            src="/images/requestTracking/Upload.png"
                            width="50px"
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                      </div>
                    )}
                  </ul>
                </div>

                {uploadDocument ? (
                  <div className="progress mb-3" style={{ height: "0.5rem" }}>
                    <div
                      className="progress-bar"
                      role="progressbar"
                      aria-valuenow={uploadDocument}
                      aria-valuemin="0"
                      aria-valuemax="100"
                      style={{ width: `${uploadDocument}%` }}
                    >
                      <span className="sr-only">{uploadDocument}</span>
                    </div>
                  </div>
                ) : null}
                <div
                  style={getBgStyle(request.DICON_File)}
                  className="border-primary border_dash rounded mb-3"
                >
                  <ul className="list-unstyled p-1">
                    {request.DICON_source.id === 3 ? (
                      <li className="mb-1 d-flex justify-content-between px-3 mt-3">
                        {request.DICON_File ? (
                          <a
                            id="fileDownload"
                            href={request.DICON_File}
                            target="_blank"
                            download
                            style={{ fontSize: "14px" }}
                            className="mt-2 text-secondary font-weight-light text-decoration-none"
                            rel="noreferrer"
                          >
                            <span htmlFor="fileDownload">
                              {request.patient.fullname} DICOM Files
                            </span>
                          </a>
                        ) : (
                          <span>DICOM Files Not available</span>
                        )}
                        {request.DICON_File ? (
                          <a
                            id="fileDownload"
                            href={request.DICON_File}
                            target="_blank"
                            download
                            rel="noreferrer"
                          >
                            <img
                              alt="file download"
                              src="/images/requestTracking/file-download.svg"
                              className="w-30 ms-3 "
                            />
                          </a>
                        ) : (
                          <div style={{ position: "relative" }}>
                            {upload ? (
                              <img
                                src="/images/load.gif"
                                alt="upload"
                                width="50px"
                              />
                            ) : null}
                            <input
                              type="file"
                              id="digitalFile360"
                              multiple
                              onChange={uploadFile}
                              style={{
                                position: "absolute",
                                left: "0px",
                                opacity: 0,
                              }}
                            />
                            <img
                              alt="file download"
                              src="/images/requestTracking/Upload.png"
                              width="50px"
                              style={{ cursor: "pointer" }}
                            />
                          </div>
                        )}
                      </li>
                    ) : null}
                    {request.DICON_source.id === 4 ? (
                      <li className="mb-1 d-flex justify-content-between px-3 mt-3">
                        {request.DICON_URL ? (
                          <a
                            id="fileDownload"
                            href={request.DICON_URL}
                            target="_blank"
                            download
                            style={{ fontSize: "14px" }}
                            className="mt-2 text-secondary font-weight-light text-decoration-none"
                            rel="noreferrer"
                          >
                            <span htmlFor="fileDownload">
                              {request.patient.fullname} Dicon URL
                            </span>
                          </a>
                        ) : (
                          <TextField
                            label="URL"
                            id="outlined-size-small"
                            size="small"
                            value={url}
                            onChange={(e) => setUrl(e.target.value)}
                          />
                        )}
                        {request.DICON_URL ? (
                          <a
                            id="fileDownload"
                            href={request.DICON_File}
                            target="_blank"
                            download
                            rel="noreferrer"
                          >
                            <img
                              alt="file download"
                              src="/images/requestTracking/file-download.svg"
                              className="w-30 ms-3 "
                            />
                          </a>
                        ) : (
                          <div style={{ position: "relative" }}>
                            <Button variant="contained" onClick={sendUrl}>
                              Send
                            </Button>
                          </div>
                        )}
                      </li>
                    ) : null}
                  </ul>
                </div>
                {uploadFiles ? (
                  <div
                    className="progress mt-2 mb-2"
                    style={{ height: "0.5rem" }}
                  >
                    <div
                      className="progress-bar"
                      role="progressbar"
                      aria-valuenow={uploadFiles}
                      aria-valuemin="0"
                      aria-valuemax="100"
                      style={{ width: `${uploadFiles}%` }}
                    >
                      <span className="sr-only">{uploadFiles}</span>
                    </div>
                  </div>
                ) : null}

                {request.descraption ? (
                  <div>
                    <p>
                      <b>Case Description: </b>
                      {request.descraption}
                    </p>
                  </div>
                ) : null}

                <div className="bg-wite">
                  <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link active"
                        id="qubx-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#qubx"
                        type="button"
                        role="tab"
                        aria-controls="qubx"
                        aria-selected="true"
                      >
                        Qubx
                      </button>
                    </li>
                  </ul>
                  <div
                    className="tab-content bg-white overflow-scroll"
                    id="myTabContent"
                  >
                    <div
                      className="tab-pane fade show active"
                      id="qubx"
                      role="tabpanel"
                      aria-labelledby="qubx-tab"
                      style={{ maxHeight: "250px" }}
                    >
                      {allQubxNotes && allQubxNotes.length > 0 ? (
                        allQubxNotes.map((note) => {
                          return (
                            <div key={note.id}>
                              <div className="box p-2">
                                <div
                                  className="info-msg me-3"
                                  id={
                                    note.created_by.groups.some((elem) =>
                                      data.user.groups.includes(elem)
                                    )
                                      ? "sender"
                                      : ""
                                  }
                                >
                                  <span className="time d-block fn_sm f-main">
                                    {note.created_by.fullname} -{" "}
                                    {new Date(note.created_at).toLocaleString()}
                                  </span>
                                </div>
                                <div className="content-msg p-2 mx-2 rounded bg_light fn_sm">
                                  {note.body}
                                </div>
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <p className="lean text-center mt-4">
                          No Notes in This Request
                        </p>
                      )}
                      <div className="p-2 d-flex justify-content-between text-center mx-2">
                        <textarea
                          className="p-2 border border-secondry  rounded w-75"
                          onChange={(e) => setNote(e.target.value)}
                          value={note}
                        ></textarea>
                        <Button variant="contained" onClick={sendNote}>
                          Send
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="printModal"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title lean" id="staticBackdropLabel"></h5>
                3D Printing
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="me-3 w-100">
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      3D Printing Center
                    </InputLabel>

                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={printingCenter}
                      label="printing-center"
                      onChange={(e) => handlePrintingCenterChange(e)}
                    >
                      <MenuItem value={10}>ten</MenuItem>
                      <MenuItem value={20}>twenty</MenuItem>
                      <MenuItem value={30}>thirty</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className="row mt-3 f-main">
                  <div className="col-6">
                    <TextField
                      id="outlined-basic"
                      label="Weight"
                      variant="outlined"
                    />
                  </div>
                  <div className="col-6">
                    <TextField
                      id="outlined-basic"
                      label="Size"
                      variant="outlined"
                    />
                  </div>
                  <div className="col-6 mt-3">
                    <TextField
                      id="outlined-basic"
                      label="Cost"
                      variant="outlined"
                    />
                  </div>
                  <div className="col-6 mt-3">
                    <TextField
                      id="outlined-basic"
                      label="Processing Time"
                      variant="outlined"
                    />
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary w-25"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  data-bs-dismiss="modal"
                  className="btn btn-primary w-25"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="shippingModal"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title lean" id="staticBackdropLabel"></h5>
                Shipping Courier
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="me-3 w-100">
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Courier
                    </InputLabel>

                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={courier}
                      disabled
                      label="Courier"
                      onChange={(e) => handleCourierChange(e)}
                    >
                      <MenuItem value={1}>One</MenuItem>
                      <MenuItem value={2}>Two</MenuItem>
                      <MenuItem value={3}>Three</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className="row mt-3 f-main">
                  <div className="col-6">
                    <TextField
                      id="outlined-basic"
                      label="Shipment Tracking ID"
                      variant="outlined"
                      disabled
                    />
                  </div>
                  <div className="col-6">
                    <TextField
                      id="outlined-basic"
                      label="Delivery Time"
                      disabled
                      variant="outlined"
                    />
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary w-25"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* <CustomToastify bgColor={success ? "green" : "red"} duration={false} /> */}
      </div>
    </>
  );
};

export default RequestTracking_3D;
