import React, { useEffect, useState } from "react";
import styles from "./login.module.css";
import "./style-login.css";
import Footer from "../footer/Footer";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CustomToastify, { toastMes } from "../../globalConf/notification";
import TextField from "@mui/material/TextField";
import { endpoints, httpNonToken } from "../../globalConf/http-non-token";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { Button } from "@mui/material";
import { reset, login } from "./../../features/auth/authSlice";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Login = () => {
  const [success, setSuccess] = useState(false);
  const [phone, setPhone] = useState("");

  const [formData, setFormData] = useState({
    name: "",
    password: "",
  });

  const { name, password } = formData;

  let navigate = useNavigate();
  const dispatch = useDispatch();
  let data = JSON.parse(localStorage.getItem("user"));
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (data) {
      navigate("/");
    }
  }, []);

  const { user, isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.auth
  );

  useEffect(() => {
    dispatch(reset());
  }, [dispatch]);

  const handleChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.name.trim() || !formData.password.trim()) {
      setSuccess(false);
      toastMes.error("Branch Name and Password are required");
      return;
    }
    const userData = { name, password };

    if (!name || !password) {
      setSuccess(false);
      toastMes.error("All Fields required");
    }

    let response = await dispatch(login(userData));
    if (response.payload.user) {
      localStorage.setItem("user", JSON.stringify(response.payload));
      navigate("/");
    } else {
      setSuccess(false);
      toastMes.error(response.payload.message);
      toastMes.error(response.payload.detail);
    }
  };

  const forgetPassword = async () => {
    if (!phone) {
      toastMes.error("Phone Number is required");
      return;
    }
    try {
      const forgetPasswordRes = await httpNonToken.put(
        `${endpoints.forgetPassword}/?mobile=${phone.trim()}`
      );
      if (forgetPasswordRes.status === 200) {
        toastMes.success("New Password will be sent to your phone");
        setPhone("");
        handleClose();
      } else {
        toastMes.success("Something went wrong ..!");
      }
    } catch ({ response }) {
      toastMes.error(response.data.message);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <div className="row m-auto rounded w_70 mt-5">
        <div className={`col p-0 ${styles.login_left_side}`}>
          <img
            src={"/images/login/bg-form.png"}
            alt="login background"
            className="w-100 h-100"
          />
        </div>
        <div className={`col p-0 bg-white ${styles.login_right_side}`}>
          <div className="text-center">
            <img
              src={"/images/login/qubx-logo-black.png"}
              alt="Qubx logo"
              className={styles.qubx_logo}
            />
          </div>
          <h5 className="text-capitalize text-center fn_main">
            Radiology Center Portal
          </h5>
          <form className="w_90 m-auto form-login">
            <div className="text-start mt-4 form-box">
              <TextField
                fullWidth
                name="name"
                id="name"
                label="Branch Name"
                onChange={handleChange}
              />
            </div>

            <div className="text-start mt-4 form-box">
              <TextField
                fullWidth
                type="password"
                name="password"
                id="password"
                label="Password"
                onChange={handleChange}
              />
            </div>
            <div className="d-flex justify-content-end">
              <p
                onClick={handleClickOpen}
                style={{ cursor: "pointer" }}
                className="text-decoration-underline cursor-pointer text-primary"
              >
                Forget Password ..!?
              </p>
            </div>

            <div className="d-flex justify-content-between buttons-g mt-4 mb-3">
              <button className="btn btn-primary w_40" onClick={handleSubmit}>
                Login
              </button>
            </div>
          </form>
        </div>
      </div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>Forget Password</DialogTitle>
        <DialogContent>
          <div>
            <i className="fas fa-exclamation-triangle fa-3x text-danger text-center d-block my-2"></i>
          </div>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="text-center"
          >
            New Random Password will sent to your Phone Number in Message, and
            you can update it later.
          </DialogContentText>
        </DialogContent>
        <TextField
          sx={{ width: "75%", margin: "auto", marginBottom: "20px" }}
          size="small"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          id="outlined-basic"
          label="Phone Number"
          variant="outlined"
        />
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          <Button onClick={forgetPassword}>Proceed Anyway</Button>
        </DialogActions>
      </Dialog>
      <CustomToastify bgColor={success ? "green" : "red"} duration={8000} />
    </>
  );
};

export default Login;
