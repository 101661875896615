// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".login_qubx_logo__bkJsO {\n  height: 100px;\n}\n@media only screen and (max-width: 800px) {\n  .login_login_left_side__NBp71 {\n    display: none;\n  }\n\n  .login_login_right_side__OoEkN .login_qubx_logo__bkJsO {\n    height: 85px;\n    margin-top: 15px;\n    margin-bottom: 15px;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/components/login/login.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;AACA;EACE;IACE,aAAa;EACf;;EAEA;IACE,YAAY;IACZ,gBAAgB;IAChB,mBAAmB;EACrB;AACF","sourcesContent":[".qubx_logo {\n  height: 100px;\n}\n@media only screen and (max-width: 800px) {\n  .login_left_side {\n    display: none;\n  }\n\n  .login_right_side .qubx_logo {\n    height: 85px;\n    margin-top: 15px;\n    margin-bottom: 15px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"qubx_logo": "login_qubx_logo__bkJsO",
	"login_left_side": "login_login_left_side__NBp71",
	"login_right_side": "login_login_right_side__OoEkN"
};
export default ___CSS_LOADER_EXPORT___;
