// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".selectdData_select_data__a42hO, .selectdData_label_data__nr\\+PJ{ \n    color: #afafb9;\n    height: 100%;\n  }\n  \n\n.selectdData_select_data__a42hO:checked + label {\n  background: #ddebff !important;\n  color: #275ff8 !important;\n}\n", "",{"version":3,"sources":["webpack://./src/components/home/selectData/selectdData.module.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,YAAY;EACd;;;AAGF;EACE,8BAA8B;EAC9B,yBAAyB;AAC3B","sourcesContent":[".select_data, .label_data{ \n    color: #afafb9;\n    height: 100%;\n  }\n  \n\n.select_data:checked + label {\n  background: #ddebff !important;\n  color: #275ff8 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"select_data": "selectdData_select_data__a42hO",
	"label_data": "selectdData_label_data__nr+PJ"
};
export default ___CSS_LOADER_EXPORT___;
