import React, { useState, useEffect } from "react";
import Styles from "./home.module.css";
// import axios from "axios";
// import JSZip from "jszip";
// import { saveAs } from "file-saver";
import { convertZipFile } from "../../globalConf/zipping";

const BrowseFile = ({ request, setRequest }) => {
  const [inputFile, setInputFile] = useState("");
  const [note, setNote] = useState("");

  useEffect(() => {
    if (request.submit) {
      setNote("");
      setInputFile("");
    }
  }, [request]);

  const handleChange = async (e) => {
    let file = await convertZipFile(
      e.target.files,
      `Patient Document`,
      "Patient-Documents.zip"
    );
    setRequest({ attach: file });
  };

  const getStyle = () => {
    let style = {};
    if (request.attach) style.backgroundColor = "#DAE8FC";
    else style.backgroundColor = "white";
    return style;
  };

  return (
    <div className="text-center my-2 mt-1 p-2">
      <div className={`${Styles.row_browse_files_box}`}>
        <div
          style={getStyle()}
          className={`w-100 mb-1 border-primary border_dash rounded py-2 ${Styles.row_browse_files}`}
        >
          <label
            className={`fn_sm fn_light bg-primary py-1 px-4 my-1 rounded ${Styles.file_upload}`}
          >
            Browse files
            <input
              type="file"
              // value={inputFile}
              multiple
              className={`h-1 ${Styles.custom_file_input}`}
              onChange={handleChange}
            />
          </label>
          <p className="m-0 fn_xs">
            {!request.attach ? (
              "You can upload up to 100 files"
            ) : (
              <span>
                Patient Attach{" "}
                <i
                  className="fa-solid fa-circle-xmark mx-1 text-danger"
                  style={{ cursor: "pointer" }}
                  onClick={() => setRequest({ attach: "" })}
                ></i>
              </span>
            )}
          </p>
        </div>
        <h5 className="text-start mt-3">Description</h5>
        <textarea
          className="w-100 bg-white border rounded"
          onChange={(e) => {
            setNote(e.target.value);
            setRequest({ descraption: e.target.value });
          }}
          rows={5}
          value={note}
        ></textarea>
      </div>
    </div>
  );
};

export default BrowseFile;
