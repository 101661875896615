// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "svg {\n  width: 18px !important;\n  margin-top: 2px;\n  margin-right: 2px;\n}\n.requestTrackPrinting_status__M9wm8 {\n  background: red;\n  padding: 2px 20px;\n  color: #dfdfdf;\n}\n\n@media only screen and (max-width: 991px) {\n  /* For tablets: */\n\n  .requestTrackPrinting_info_boxes__9e486 {\n    width: 90%;\n  }\n  .requestTrackPrinting_info_box__63SDQ {\n    margin-bottom: 10px;\n  }\n  .requestTrackPrinting_input-boxes__uNUNd {\n    width: 100%;\n  }\n  .requestTrackPrinting_backHeader__KS9Gp {\n    width: 100%;\n  }\n}\n\n.requestTrackPrinting_hidden-xs-up__VWtM1 {\n  display: none;\n}\n", "",{"version":3,"sources":["webpack://./src/components/requestTracking/requestTrackPrinting/requestTrackPrinting.module.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,eAAe;EACf,iBAAiB;AACnB;AACA;EACE,eAAe;EACf,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE,iBAAiB;;EAEjB;IACE,UAAU;EACZ;EACA;IACE,mBAAmB;EACrB;EACA;IACE,WAAW;EACb;EACA;IACE,WAAW;EACb;AACF;;AAEA;EACE,aAAa;AACf","sourcesContent":["svg {\n  width: 18px !important;\n  margin-top: 2px;\n  margin-right: 2px;\n}\n.status {\n  background: red;\n  padding: 2px 20px;\n  color: #dfdfdf;\n}\n\n@media only screen and (max-width: 991px) {\n  /* For tablets: */\n\n  .info_boxes {\n    width: 90%;\n  }\n  .info_box {\n    margin-bottom: 10px;\n  }\n  .input-boxes {\n    width: 100%;\n  }\n  .backHeader {\n    width: 100%;\n  }\n}\n\n.hidden-xs-up {\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"status": "requestTrackPrinting_status__M9wm8",
	"info_boxes": "requestTrackPrinting_info_boxes__9e486",
	"info_box": "requestTrackPrinting_info_box__63SDQ",
	"input-boxes": "requestTrackPrinting_input-boxes__uNUNd",
	"backHeader": "requestTrackPrinting_backHeader__KS9Gp",
	"hidden-xs-up": "requestTrackPrinting_hidden-xs-up__VWtM1"
};
export default ___CSS_LOADER_EXPORT___;
