import React, { useEffect, useState } from "react";
import Styles from "./header.module.css";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Badge from "@mui/material/Badge";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import CustomToastify, { toastMes } from "../../globalConf/notification";
import { httpNonToken, endpoints } from "../../globalConf/http-non-token";
import { httpToken, endpointsToken } from "../../globalConf/http";
import NotificationsIcon from "@mui/icons-material/Notifications";
import IconButton from "@mui/material/IconButton";
import moment from "moment";

import "./style.css";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  // padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const Header = () => {
  const { user } = useSelector((state) => state.auth);
  const [expanded, setExpanded] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [notifications, setNotifications] = useState({});
  const [success, setSuccess] = useState([]);
  const [newNotificationsNumber, setNewNotificationsNumber] = useState(0);
  const [load, setLoad] = useState(true);

  let data = {};
  data = JSON.parse(localStorage.getItem("user"));

  const navigate = useNavigate();

  useEffect(() => {
    let isMounted = true;

    (async () => {
      if (data) {
        try {
          const notificationsRes = await httpNonToken.get(
            `${endpoints.notifications}?to=rc&request__created_by__branch_profile__name=${data.user.branch.name}`
          );
          if (isMounted) {
            setNotifications(notificationsRes.data);
            setNewNotificationsNumber(
              Object.values(notificationsRes.data)[0]["count"] +
                Object.values(notificationsRes.data)[1]["count"] +
                Object.values(notificationsRes.data)[2]["count"] +
                Object.values(notificationsRes.data)[3]["count"] +
                Object.values(notificationsRes.data)[4]["count"] +
                Object.values(notificationsRes.data)[5]["count"] +
                Object.values(notificationsRes.data)[6]["count"] +
                Object.values(notificationsRes.data)[7]["count"]
            );
            setLoad(false);
          }
        } catch ({ response }) {
          setSuccess(false);
          toastMes.error(response.data.message);
        }
      }
    })();
    return () => {
      isMounted = false;
    };
  }, [expanded]);

  const onLogout = async () => {
    localStorage.removeItem("user");
    navigate("/login");
  };

  const changePassword = () => {
    navigate(`/reset-password/${data.user.id}`, {
      state: { changePasswordFlag: true },
    });
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const navToRequest = (requestId, serviceId) => {
    navigate(`/${serviceId === 6 ? "request_360" : "request_3D"}/${requestId}`);
  };

  const handleChange = (panel, value) => async (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
    if (!expanded || expanded !== "") {
      try {
        await httpNonToken.put(`${endpoints.updateGroupNotifications}`, {
          type: value,
        });
      } catch ({ response }) {
        console.log(response.error.message);
      }
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  // if (load) {
  //   return "";
  // }

  return (
    <div>
      <nav
        className={`navbar navbar-expand-lg navbar-light bg_dark ${Styles.nav_section}`}
      >
        <div className={`container-fluid ${Styles.nav_box}`}>
          <Link className="navbar-brand" to="/">
            <img
              // src="/images/header/qubx_logo.svg"
              src="/images/header/logo.png"
              className={Styles.qubx_logo}
              alt="qubx"
            />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <NavLink
                  className="nav-link fn_light"
                  aria-current="page"
                  to="/"
                >
                  New Request
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link fn_light" to="/requests">
                  Request Tracking
                </NavLink>
              </li>
            </ul>
          </div>
          <div className="">
            <ul
              className={`navbar-nav me-auto mb-2 mb-lg-0 position-absolute list-notification ${Styles.header_info}`}
            >
              <li className="nav-item float-start">
                <IconButton
                  size="large"
                  className="text-white"
                  aria-label={`show ${newNotificationsNumber} new notifications`}
                  color="inherit"
                  onClick={handleClick}
                >
                  <Badge
                    size="large"
                    badgeContent={
                      newNotificationsNumber ? newNotificationsNumber : "0"
                    }
                    color="error"
                  >
                    <NotificationsIcon />
                  </Badge>
                </IconButton>
              </li>
              {Object.keys(notifications).length > 0 ? (
                <li className="nav-item float-start">
                  <div>
                    <Popover
                      id={id}
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      sx={{ width: "100%", maxHeight: "500px" }}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                    >
                      <div
                        style={{ minWidth: "380px", backgroundColor: "" }}
                        className="d-flex justify-content-between text-white bg-primary"
                      >
                        <Typography sx={{ p: 2 }}>Notifications</Typography>
                        <Typography sx={{ p: 2 }}>
                          {newNotificationsNumber} New
                        </Typography>
                      </div>
                      {notifications["Pending"].count > 0 ? (
                        <div
                          className="d-flex justify-content-between py-1 px-4"
                          style={{ width: "450px" }}
                        >
                          <Accordion
                            expanded={expanded === "panel1"}
                            onChange={handleChange("panel1", "Pending")}
                          >
                            <AccordionSummary
                              aria-controls="panel1d-content"
                              id="panel1d-header"
                            >
                              <div
                                style={{
                                  minWidth: "380px",
                                  backgroundColor: "",
                                }}
                                className="d-flex justify-content-between"
                              >
                                <Typography sx={{ p: 2, lineHeight: 2.5 }}>
                                  Pending
                                </Typography>
                                <Typography sx={{ p: 2 }}>
                                  <Badge
                                    className="text-white"
                                    sx={{
                                      backgroundColor: "#EABC65",
                                      p: 1,
                                      px: 2,
                                      borderRadius: "18px",
                                    }}
                                  >
                                    {notifications["Pending"].count}
                                  </Badge>
                                </Typography>
                              </div>
                            </AccordionSummary>
                            <AccordionDetails>
                              <List
                                sx={{
                                  width: "100%",
                                  bgcolor: "background.paper",
                                  p: 0,
                                }}
                              >
                                {notifications["Pending"].notifications.length >
                                0 ? (
                                  notifications["Pending"].notifications.map(
                                    (notification) => {
                                      return (
                                        <ListItem
                                          key={notification.id}
                                          sx={{
                                            cursor: "pointer",
                                            padding: 0,
                                            marginBottom: "10px",
                                            fontSize: "14px",
                                          }}
                                          className="row"
                                        >
                                          <span
                                            className="col-9 text-secondary"
                                            onClick={() =>
                                              navToRequest(
                                                notification.request.id,
                                                notification.request.service.id
                                              )
                                            }
                                          >
                                            <i className="fa-solid fa-check text-success mx-2"></i>
                                            {notification.content}
                                          </span>
                                          <span
                                            style={{
                                              fontSize: "11px",
                                              color: "grey",
                                            }}
                                            className="col-3 text-right"
                                          >
                                            {moment(
                                              notification.request
                                                .updatedAtStatus
                                            ).fromNow()}
                                          </span>
                                        </ListItem>
                                      );
                                    }
                                  )
                                ) : (
                                  <p className="text-center font-weight-light mt-1">
                                    New Notifications Not available
                                  </p>
                                )}
                              </List>
                            </AccordionDetails>
                          </Accordion>
                        </div>
                      ) : null}
                      <Divider light />
                      {notifications["Ready"].count > 0 ? (
                        <div
                          className="d-flex justify-content-between py-1 px-4"
                          style={{ width: "450px" }}
                        >
                          <Accordion
                            expanded={expanded === "panel2"}
                            onChange={handleChange("panel2", "Ready")}
                          >
                            <AccordionSummary
                              aria-controls="panel1d-content"
                              id="panel1d-header"
                            >
                              <div
                                style={{
                                  minWidth: "380px",
                                  backgroundColor: "",
                                }}
                                className="d-flex justify-content-between"
                              >
                                <Typography sx={{ p: 2, lineHeight: 2.5 }}>
                                  Ready
                                </Typography>
                                <Typography sx={{ p: 2 }}>
                                  <Badge
                                    className="text-white"
                                    sx={{
                                      backgroundColor: "#93AC82",
                                      p: 1,
                                      px: 2,
                                      borderRadius: "18px",
                                    }}
                                  >
                                    {notifications["Ready"].count}
                                  </Badge>
                                </Typography>
                              </div>
                            </AccordionSummary>
                            <AccordionDetails>
                              <List
                                sx={{
                                  width: "100%",
                                  bgcolor: "background.paper",
                                  p: 0,
                                }}
                              >
                                {notifications["Ready"].notifications.length >
                                0 ? (
                                  notifications["Ready"].notifications.map(
                                    (notification) => {
                                      return (
                                        <ListItem
                                          key={notification.id}
                                          sx={{
                                            cursor: "pointer",
                                            padding: 0,
                                            marginBottom: "10px",
                                            fontSize: "14px",
                                          }}
                                          className="row"
                                        >
                                          <span
                                            className="col-9 text-secondary"
                                            onClick={() =>
                                              navToRequest(
                                                notification.request.id,
                                                notification.request.service.id
                                              )
                                            }
                                          >
                                            <i className="fa-solid fa-check text-success mx-2"></i>
                                            {notification.content}
                                          </span>
                                          <span
                                            style={{
                                              fontSize: "11px",
                                              color: "grey",
                                            }}
                                            className="col-3 text-right"
                                          >
                                            {moment(
                                              notification.request
                                                .updatedAtStatus
                                            ).fromNow()}
                                          </span>
                                        </ListItem>
                                      );
                                    }
                                  )
                                ) : (
                                  <p className="text-center font-weight-light mt-1">
                                    New Notifications Not available
                                  </p>
                                )}
                              </List>
                            </AccordionDetails>
                          </Accordion>
                        </div>
                      ) : null}
                      <Divider light />
                      {notifications["Returned"].count > 0 ? (
                        <div
                          className="d-flex justify-content-between py-1 px-4"
                          style={{ width: "450px" }}
                        >
                          <Accordion
                            expanded={expanded === "panel3"}
                            onChange={handleChange("panel3", "Returned")}
                          >
                            <AccordionSummary
                              aria-controls="panel1d-content"
                              id="panel1d-header"
                            >
                              <div
                                style={{
                                  minWidth: "380px",
                                  backgroundColor: "",
                                }}
                                className="d-flex justify-content-between"
                              >
                                <Typography sx={{ p: 2, lineHeight: 2.5 }}>
                                  Returned
                                </Typography>
                                <Typography sx={{ p: 2 }}>
                                  <Badge
                                    className="text-white"
                                    sx={{
                                      backgroundColor: "#F5DBDA",
                                      p: 1,
                                      px: 2,
                                      borderRadius: "18px",
                                    }}
                                  >
                                    {notifications["Returned"].count}
                                  </Badge>
                                </Typography>
                              </div>
                            </AccordionSummary>
                            <AccordionDetails>
                              <List
                                sx={{
                                  width: "100%",
                                  bgcolor: "background.paper",
                                  p: 0,
                                }}
                              >
                                {notifications["Returned"].notifications
                                  .length > 0 ? (
                                  notifications["Returned"].notifications.map(
                                    (notification) => {
                                      return (
                                        <ListItem
                                          key={notification.id}
                                          sx={{
                                            cursor: "pointer",
                                            padding: 0,
                                            marginBottom: "10px",
                                            fontSize: "14px",
                                          }}
                                          className="row"
                                        >
                                          <span
                                            className="col-9 text-secondary"
                                            onClick={() =>
                                              navToRequest(
                                                notification.request.id,
                                                notification.request.service.id
                                              )
                                            }
                                          >
                                            <i className="fa-solid fa-check text-success mx-2"></i>{" "}
                                            {notification.content}
                                          </span>
                                          <span
                                            style={{
                                              fontSize: "11px",
                                              color: "grey",
                                            }}
                                            className="col-3 text-right"
                                          >
                                            {moment(
                                              notification.request
                                                .updatedAtStatus
                                            ).fromNow()}
                                          </span>
                                        </ListItem>
                                      );
                                    }
                                  )
                                ) : (
                                  <p className="text-center font-weight-light mt-1">
                                    New Notifications Not available
                                  </p>
                                )}
                              </List>
                            </AccordionDetails>
                          </Accordion>
                        </div>
                      ) : null}
                      <Divider light />
                      {notifications["Ready for printing"].count > 0 ? (
                        <div
                          className="d-flex justify-content-between py-1 px-4"
                          style={{ width: "450px" }}
                        >
                          <Accordion
                            expanded={expanded === "panel4"}
                            onChange={handleChange(
                              "panel4",
                              "Ready for printing"
                            )}
                          >
                            <AccordionSummary
                              aria-controls="panel1d-content"
                              id="panel1d-header"
                            >
                              <div
                                style={{
                                  minWidth: "380px",
                                  backgroundColor: "",
                                }}
                                className="d-flex justify-content-between"
                              >
                                <Typography sx={{ p: 2, lineHeight: 2.5 }}>
                                  Ready for printing
                                </Typography>
                                <Typography sx={{ p: 2 }}>
                                  <Badge
                                    className="text-white"
                                    sx={{
                                      backgroundColor: "#4DD172",
                                      p: 1,
                                      px: 2,
                                      borderRadius: "18px",
                                    }}
                                  >
                                    {notifications["Ready for printing"].count}
                                  </Badge>
                                </Typography>
                              </div>
                            </AccordionSummary>
                            <AccordionDetails>
                              <List
                                sx={{
                                  width: "100%",
                                  bgcolor: "background.paper",
                                  p: 0,
                                }}
                              >
                                {notifications["Ready for printing"]
                                  .notifications.length > 0 ? (
                                  notifications[
                                    "Ready for printing"
                                  ].notifications.map((notification) => {
                                    return (
                                      <ListItem
                                        key={notification.id}
                                        sx={{
                                          cursor: "pointer",
                                          padding: 0,
                                          marginBottom: "10px",
                                          fontSize: "14px",
                                        }}
                                        className="row"
                                      >
                                        <span
                                          className="col-9 text-secondary"
                                          onClick={() =>
                                            navToRequest(
                                              notification.request.id,
                                              notification.request.service.id
                                            )
                                          }
                                        >
                                          <i className="fa-solid fa-check text-success mx-2"></i>
                                          {notification.content}
                                        </span>
                                        <span
                                          style={{
                                            fontSize: "11px",
                                            color: "grey",
                                          }}
                                          className="col-3 text-right"
                                        >
                                          {moment(
                                            notification.request.updatedAtStatus
                                          ).fromNow()}
                                        </span>
                                      </ListItem>
                                    );
                                  })
                                ) : (
                                  <p className="text-center font-weight-light mt-1">
                                    New Notifications Not available
                                  </p>
                                )}
                              </List>
                            </AccordionDetails>
                          </Accordion>
                        </div>
                      ) : null}
                      <Divider light />
                      {notifications["Quality Assurance"].count > 0 ? (
                        <div
                          className="d-flex justify-content-between py-1 px-4"
                          style={{ width: "450px" }}
                        >
                          <Accordion
                            expanded={expanded === "panel5"}
                            onChange={handleChange(
                              "panel5",
                              "Quality Assurance"
                            )}
                          >
                            <AccordionSummary
                              aria-controls="panel1d-content"
                              id="panel1d-header"
                            >
                              <div
                                style={{
                                  minWidth: "380px",
                                  backgroundColor: "",
                                }}
                                className="d-flex justify-content-between"
                              >
                                <Typography sx={{ p: 2, lineHeight: 2.5 }}>
                                  Quality Assurance
                                </Typography>
                                <Typography sx={{ p: 2 }}>
                                  <Badge
                                    className="text-white"
                                    sx={{
                                      backgroundColor: "#4EC9B0",
                                      p: 1,
                                      px: 2,
                                      borderRadius: "18px",
                                    }}
                                  >
                                    {notifications["Quality Assurance"].count}
                                  </Badge>
                                </Typography>
                              </div>
                            </AccordionSummary>
                            <AccordionDetails>
                              <List
                                sx={{
                                  width: "100%",
                                  bgcolor: "background.paper",
                                  p: 0,
                                }}
                              >
                                {notifications["Quality Assurance"]
                                  .notifications.length > 0 ? (
                                  notifications[
                                    "Quality Assurance"
                                  ].notifications.map((notification) => {
                                    return (
                                      <ListItem
                                        key={notification.id}
                                        sx={{
                                          cursor: "pointer",
                                          padding: 0,
                                          marginBottom: "10px",
                                          fontSize: "14px",
                                        }}
                                        className="row"
                                      >
                                        <span
                                          className="col-9 text-secondary"
                                          onClick={() =>
                                            navToRequest(
                                              notification.request.id,
                                              notification.request.service.id
                                            )
                                          }
                                        >
                                          <i className="fa-solid fa-check text-success mx-2"></i>{" "}
                                          {notification.content}
                                        </span>
                                        <span
                                          style={{
                                            fontSize: "11px",
                                            color: "grey",
                                          }}
                                          className="col-3 text-right"
                                        >
                                          {moment(
                                            notification.request.updatedAtStatus
                                          ).fromNow()}
                                        </span>
                                      </ListItem>
                                    );
                                  })
                                ) : (
                                  <p className="text-center font-weight-light mt-1">
                                    New Notifications Not available
                                  </p>
                                )}
                              </List>
                            </AccordionDetails>
                          </Accordion>
                        </div>
                      ) : null}
                      <Divider light />
                      {notifications["Ready for shipping"].count > 0 ? (
                        <div
                          className="d-flex justify-content-between py-1 px-4"
                          style={{ width: "450px" }}
                        >
                          <Accordion
                            expanded={expanded === "panel6"}
                            onChange={handleChange(
                              "panel6",
                              " Ready for shipping"
                            )}
                          >
                            <AccordionSummary
                              aria-controls="panel1d-content"
                              id="panel1d-header"
                            >
                              <div
                                style={{
                                  minWidth: "380px",
                                  backgroundColor: "",
                                }}
                                className="d-flex justify-content-between"
                              >
                                <Typography sx={{ p: 2, lineHeight: 2.5 }}>
                                  Ready for shipping
                                </Typography>
                                <Typography sx={{ p: 2 }}>
                                  <Badge
                                    className="text-white"
                                    sx={{
                                      backgroundColor: "#82CB6A",
                                      p: 1,
                                      px: 2,
                                      borderRadius: "18px",
                                    }}
                                  >
                                    {notifications["Ready for shipping"].count}
                                  </Badge>
                                </Typography>
                              </div>
                            </AccordionSummary>
                            <AccordionDetails>
                              <List
                                sx={{
                                  width: "100%",
                                  bgcolor: "background.paper",
                                  p: 0,
                                }}
                              >
                                {notifications["Ready for shipping"]
                                  .notifications.length > 0 ? (
                                  notifications[
                                    "Ready for shipping"
                                  ].notifications.map((notification) => {
                                    return (
                                      <ListItem
                                        key={notification.id}
                                        sx={{
                                          cursor: "pointer",
                                          padding: 0,
                                          marginBottom: "10px",
                                          fontSize: "14px",
                                        }}
                                        className="row"
                                      >
                                        <span
                                          className="col-9 text-secondary"
                                          onClick={() =>
                                            navToRequest(
                                              notification.request.id,
                                              notification.request.service.id
                                            )
                                          }
                                        >
                                          <i className="fa-solid fa-check text-success mx-2"></i>{" "}
                                          {notification.content}
                                        </span>
                                        <span
                                          style={{
                                            fontSize: "11px",
                                            color: "grey",
                                          }}
                                          className="col-3 text-right"
                                        >
                                          {moment(
                                            notification.request.updatedAtStatus
                                          ).fromNow()}
                                        </span>
                                      </ListItem>
                                    );
                                  })
                                ) : (
                                  <p className="text-center font-weight-light mt-1">
                                    New Notifications Not available
                                  </p>
                                )}
                              </List>
                            </AccordionDetails>
                          </Accordion>
                        </div>
                      ) : null}
                      <Divider light />
                      {notifications["Follow up with Courier"].count > 0 ? (
                        <div
                          className="d-flex justify-content-between py-1 px-4"
                          style={{ width: "450px" }}
                        >
                          <Accordion
                            expanded={expanded === "panel7"}
                            onChange={handleChange(
                              "panel7",
                              "Follow up with Courier"
                            )}
                          >
                            <AccordionSummary
                              aria-controls="panel1d-content"
                              id="panel1d-header"
                            >
                              <div
                                style={{
                                  minWidth: "380px",
                                  backgroundColor: "",
                                }}
                                className="d-flex justify-content-between"
                              >
                                <Typography sx={{ p: 2, lineHeight: 2.5 }}>
                                  Follow up with Courier
                                </Typography>
                                <Typography sx={{ p: 2 }}>
                                  <Badge
                                    className="text-white"
                                    sx={{
                                      backgroundColor: "#E9F0FF",
                                      p: 1,
                                      px: 2,
                                      borderRadius: "18px",
                                    }}
                                  >
                                    {
                                      notifications["Follow up with Courier"]
                                        .count
                                    }
                                  </Badge>
                                </Typography>
                              </div>
                            </AccordionSummary>
                            <AccordionDetails>
                              <List
                                sx={{
                                  width: "100%",
                                  bgcolor: "background.paper",
                                  p: 0,
                                }}
                              >
                                {notifications["Follow up with Courier"]
                                  .notifications.length > 0 ? (
                                  notifications[
                                    "Follow up with Courier"
                                  ].notifications.map((notification) => {
                                    return (
                                      <ListItem
                                        key={notification.id}
                                        sx={{
                                          cursor: "pointer",
                                          padding: 0,
                                          marginBottom: "10px",
                                          fontSize: "14px",
                                        }}
                                        className="row"
                                      >
                                        <span
                                          className="col-9 text-secondary"
                                          onClick={() =>
                                            navToRequest(
                                              notification.request.id,
                                              notification.request.service.id
                                            )
                                          }
                                        >
                                          <i className="fa-solid fa-check text-success mx-2"></i>{" "}
                                          {notification.content}
                                        </span>
                                        <span
                                          style={{
                                            fontSize: "11px",
                                            color: "grey",
                                          }}
                                          className="col-3 text-right"
                                        >
                                          {moment(
                                            notification.request.updatedAtStatus
                                          ).fromNow()}
                                        </span>
                                      </ListItem>
                                    );
                                  })
                                ) : (
                                  <p className="text-center font-weight-light mt-1">
                                    New Notifications Not available
                                  </p>
                                )}
                              </List>
                            </AccordionDetails>
                          </Accordion>
                        </div>
                      ) : null}
                      <Divider light />
                      {notifications["Cancelled"].count > 0 ? (
                        <div
                          className="d-flex justify-content-between py-1 px-4"
                          style={{ width: "450px" }}
                        >
                          <Accordion
                            expanded={expanded === "panel8"}
                            onChange={handleChange("panel8", "Cancelled")}
                          >
                            <AccordionSummary
                              aria-controls="panel1d-content"
                              id="panel1d-header"
                            >
                              <div
                                style={{
                                  minWidth: "380px",
                                  backgroundColor: "",
                                }}
                                className="d-flex justify-content-between"
                              >
                                <Typography sx={{ p: 2, lineHeight: 2.5 }}>
                                  Cancelled
                                </Typography>
                                <Typography sx={{ p: 2 }}>
                                  <Badge
                                    className="text-white"
                                    sx={{
                                      backgroundColor: "#EE3737",
                                      p: 1,
                                      px: 2,
                                      borderRadius: "18px",
                                    }}
                                  >
                                    {notifications["Cancelled"].count}
                                  </Badge>
                                </Typography>
                              </div>
                            </AccordionSummary>
                            <AccordionDetails>
                              <List
                                sx={{
                                  width: "100%",
                                  bgcolor: "background.paper",
                                  p: 0,
                                }}
                              >
                                {notifications["Cancelled"].notifications
                                  .length > 0 ? (
                                  notifications["Cancelled"].notifications.map(
                                    (notification) => {
                                      return (
                                        <ListItem
                                          key={notification.id}
                                          sx={{
                                            cursor: "pointer",
                                            padding: 0,
                                            marginBottom: "10px",
                                            fontSize: "14px",
                                          }}
                                          className="row"
                                        >
                                          <span
                                            className="col-9"
                                            onClick={() =>
                                              navToRequest(
                                                notification.request.id,
                                                notification.request.service.id
                                              )
                                            }
                                          >
                                            <i className="fa-solid fa-check text-success mx-2"></i>{" "}
                                            {notification.content}
                                          </span>
                                          <span
                                            style={{
                                              fontSize: "11px",
                                              color: "grey",
                                            }}
                                            className="col-3 text-right"
                                          >
                                            {moment(
                                              notification.request
                                                .updatedAtStatus
                                            ).fromNow()}
                                          </span>
                                        </ListItem>
                                      );
                                    }
                                  )
                                ) : (
                                  <p className="text-center font-weight-light mt-1">
                                    New Notifications Not available
                                  </p>
                                )}
                              </List>
                            </AccordionDetails>
                          </Accordion>
                        </div>
                      ) : null}
                    </Popover>
                  </div>
                </li>
              ) : null}
              <li className="nav-item float-start">
                <p className="text-white mt-2 mx-2 text-uppercase">
                  {data === null ? null : data.user.branch.name}
                </p>
              </li>
              <li className="nav-item dropdown">
                <Link
                  className="nav-link fn_light dropdown-toggle"
                  to="#"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span className="text-capitalize">
                    {user ? user.user.fullname : null}
                  </span>
                </Link>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <Link className="dropdown-item" to="/">
                      Home
                    </Link>
                  </li>
                  <li>
                    <span className="dropdown-item" onClick={changePassword}>
                      Change password
                    </span>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li>
                    <button className="dropdown-item" onClick={onLogout}>
                      Logout
                    </button>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <CustomToastify bgColor={success ? "green" : "red"} duration={8000} />
    </div>
  );
};

export default Header;
