/* eslint-disable */
import React from "react";
import axios from "axios";

export const basicUrl = `https://py.qubx3d.com/api/v1`;
export const ipAddressToken = "http://188.166.169.201:8088/api/v1";
const instance = axios.create({
  baseURL: `${basicUrl}`,
});

let data = JSON.parse(localStorage.getItem("user"));
let token;
if (data) {
  token = data.access;
}

instance.interceptors.request.use((config) => {
  config.headers.authorization = `Bearer ${
    JSON.parse(localStorage.getItem("user")).access
  }`;
  return config;
});

export const httpToken = instance;
export const endpointsToken = {
  request: "/request",
  updateRequest: "/request/update",
  createRequest: "/request/create",
  requestStatus: "/status",
  smsTemplates: "/sms/template",
  qubxOperator: "/employeeProfile",
  note: "/note",
  createNote: "/note/create",
  sirvUpload: "/sirv/upload-file/",
  diconSource: "/diconSource",
  requestPrice: "/request/price/",
  user: "/user/",
  changePassword: "/user/changePassword/",
  createDoctor: "/user/doctor-rc/create",
  notifications: "/notifications",
  updateNotification: "/notifications/update",
  updateGroupNotifications: "/notifications/read",
};
