import React, { useState } from "react";

const WarningNotFound = ({ request, showForm, setShowForm }) => {
  return (
    <div className="text-center">
      <p className="mb-1" style={{ fontSize: "12px" }}>
        Mobile is not found, please add a new patient
      </p>
      <button
        style={{ fontSize: "12px" }}
        className="btn btn-primary"
        onClick={() => setShowForm(!showForm)}
      >
        {request.patient ? "Update Patient" : "Add Patient"}
      </button>
    </div>
  );
};

export default WarningNotFound;
