import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Styles from "./home.module.css";

const Select360Digital = ({ request, setRequest }) => {
  const [digitalSection, setDigitalSection] = useState(true);
  const [digitalService, setDigitalService] = useState([]);
  const [selected3DService, setSelected3DService] = useState(
    request.digitalService
  );
  useEffect(() => {
    if (request.submit) {
      setSelected3DService("");
    }
    setSelected3DService("");
    fetch("https://py.qubx3d.com/api/v1/service?type__id=3")
      .then((response) => response.json())
      .then((data) => setDigitalService(data.results));
    setSelected3DService(request.digitalService);
  }, [request.submit]);

  const handleChange = (e) => {
    setSelected3DService(parseFloat(e.target.id));
    setRequest({
      digitalService: parseFloat(e.target.id),
      digitalServiceName: e.target.name,
    });
    setDigitalSection(!digitalSection);
  };

  const resetChoice = () => {
    setSelected3DService("");
    setRequest({ digitalServiceName: "", digitalService: "" });
    setDigitalSection(!digitalSection);
  };
  const getStyle = () => {
    let style = {};
    if (request.digitalService) style.backgroundColor = "#DAE8FC";
    else style.backgroundColor = "white";
    return style;
  };
  return (
    <>
      {digitalSection ? (
        <div
          style={getStyle()}
          className={`border rounded mt-3 text-center py-3 ${Styles.printing3d_section}`}
        >
          <img
            src="/images/global/360-printing.svg"
            className="my-3 w-40"
            alt="360 digital"
            onClick={() => setDigitalSection(!digitalSection)}
          />
          <h6>
            {`360 Visual ${
              request.digitalServiceName
                ? `( ${request.digitalServiceName} )`
                : ""
            }`}
          </h6>
        </div>
      ) : (
        <div
          className={`bg-white mt-3 mh-100 border rounded ${Styles.printing3d_section}`}
          style={{ minHeight: "250px", maxHeight: "250px", overflow: "auto" }}
        >
          <div className="border-bottom">
            <img
              src="/images/global/360-printing.svg"
              className="my-1 w_15 cursor-pointer"
              alt="360-digital"
              onClick={() => setDigitalSection(!digitalSection)}
            />
          </div>
          <div className="mt-2 ms-2 p-1">
            <div className="d-flex justify-content-end">
              <Button
                size="small"
                onClick={resetChoice}
                variant="outlined"
                color="error"
              >
                Clear Service
              </Button>
            </div>
            {digitalService.map((printing, id) => (
              <div className="form-check  fn_sm fw-bold m-2 ls-1" key={id}>
                <input
                  className={`form-check-input ${Styles.select_printing}`}
                  type="radio"
                  name={printing.name}
                  id={printing.id}
                  checked={selected3DService === printing.id}
                  value={selected3DService}
                  onChange={(e) => handleChange(e)}
                />
                <label className="form-check-label" htmlFor={printing.id}>
                  {printing.name}
                </label>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default Select360Digital;
