import React, { useState } from "react";
import AddPatientForm from "./AddPatientForm";
import SearchPatient from "./SearchPatient";

const SectionSearch = ({
  request,
  setShowComponent,
  showComponent,
  setRequest,
}) => {
  const [showForm, setShowForm] = useState(true);

  return showForm ? (
    <SearchPatient
      request={request}
      setShowComponent={setShowComponent}
      setRequest={setRequest}
      showForm={showForm}
      setShowForm={setShowForm}
    />
  ) : (
    <AddPatientForm
      request={request}
      setShowComponent={setShowComponent}
      showComponent={showComponent}
      setRequest={setRequest}
      showForm={showForm}
      setShowForm={setShowForm}
    />
  );
};

export default SectionSearch;
