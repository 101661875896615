import React, { useState, useEffect } from "react";
import "./requestTrackTable.css";
import { Link, useNavigate } from "react-router-dom";
import { httpNonToken, endpoints } from "../../globalConf/http-non-token";
import { httpToken, endpointsToken } from "../../globalConf/http";
import CustomToastify, { toastMes } from "../../globalConf/notification";
import Table from "@mui/material/Table";
import Tooltip from "@mui/material/Tooltip";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import "react-datepicker/dist/react-datepicker.css";
import Loading from "../loading/loading";
//
import { enGB } from "date-fns/locale";
import { DateRangePicker, START_DATE, END_DATE } from "react-nice-dates";
import "react-nice-dates/build/style.css";

import moment from "moment";
import axios from "axios";

const RequestTrackTable = () => {
  const [date, setDate] = useState();
  const [page, setPage] = useState(1);
  const [user, setUser] = useState({});
  const [pageSize, setPageSize] = useState(20);
  const [success, setSuccess] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [loadingFlag, setLoadingFlag] = useState(true);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [allRequestStatus, setAllRequestStatus] = useState([]);
  const [patientPhone, setPatientPhone] = useState("");
  //
  // const [value, setValue] = React.useState([null, null]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  let data = JSON.parse(localStorage.getItem("user"));

  const navigate = useNavigate();

  const handleChange = (event) => {
    setSelectedStatus(event.target.value);
    setPage(1);
  };

  useEffect(() => {
    let isMounted = true;

    (async () => {
      if (data) {
        setLoadingFlag(true);
        try {
          const res = await httpToken.get(
            `${endpointsToken.request}?created_by__branch_profile__name=${
              data.user.branch.name
            }&page=${page}${
              selectedStatus ? `&statu__id=${selectedStatus}` : ""
            }${
              startDate
                ? `&created_at__date__gte=${
                    new Date(startDate).toISOString().split("T")[0]
                  }`
                : ""
            }${
              endDate
                ? `&created_at__date__lte=${
                    new Date(endDate).toISOString().split("T")[0]
                  }`
                : ""
            }&page_size=${pageSize}${
              patientPhone ? `&patient__mobile__contains=${patientPhone}` : ""
            }`
          );

          const statusRes = await httpNonToken.get(
            `${endpoints.requestStatus}`
          );
          if (isMounted) {
            setTableData(res.data);
            setAllRequestStatus(statusRes.data.results);
            setLoadingFlag(false);
          }
        } catch ({ response }) {
          if (response.status === 401 && response.data.code) {
            localStorage.removeItem("user");
            navigate("/login");
            return;
          }
          setSuccess(false);
          toastMes.error(response.data.message);
        }
      } else {
        navigate("/login");
      }
    })();
    return () => {
      isMounted = false;
    };
  }, [selectedStatus, endDate, startDate, page, pageSize]);

  if (loadingFlag) {
    return <Loading />;
  }

  const handleDateChange = (e) => {
    setDate(e.target.value);
  };

  const getTodayRequests = () => {
    setPage(1);
    let today = moment(new Date()).format("YYYY-MM-DD");
    setDate(today);
  };

  const getShippedRequests = () => {
    setPage(1);
    setSelectedStatus(10);
  };

  const getPendingRequests = () => {
    setPage(1);
    setSelectedStatus(2);
  };

  const getReturnedRequests = () => {
    setPage(1);
    setSelectedStatus(4);
  };

  const handlePageChange = (pageNum) => {
    setPage(pageNum);
  };

  const getRequestsByPhone = async (e) => {
    setPatientPhone(e.target.value);
    try {
      const res = await httpToken.get(
        `${endpointsToken.request}?created_by__branch_profile__name=${
          data.user.branch.name
        }&page=${page}${selectedStatus ? `&statusView=${selectedStatus}` : ""}${
          date ? `&created_at__date=${date}` : ""
        }&page_size=${pageSize}${
          patientPhone ? `&patient__mobile__contains=${e.target.value}` : ""
        }`
      );

      setTableData(res.data);
    } catch ({ response }) {
      setSuccess(false);
      toastMes.error(response.data.message);
    }
  };

  const clearAllFilter = () => {
    setSelectedStatus("");
    setStartDate(null);
    setEndDate(null);
  };
  const getStyle = (id) => {
    let style = {};
    style.borderRadius = "0px";
    style.textAlign = "center";
    style.padding = "5px";
    if (id === "In progress") {
      style.backgroundColor = "#77c3f6";
    } else if (id === "Delivered to Patient") {
      style.backgroundColor = "#A6efb5";
    } else if (id === "Returned") {
      style.backgroundColor = "#Ff4b4b";
    } else if (id === "Ready") {
      style.backgroundColor = "#Feff6b";
    } else if (id === "3D Printing") {
      style.backgroundColor = "#D5a4ff";
    } else if (id === "Cancelled") {
      style.backgroundColor = "#E6e6e6";
    } else if (id === "Pending") {
      style.backgroundColor = "#FE9F44";
    } else {
      style.backgroundColor = "lightgrey";
    }
    return style;
  };
  return (
    <div className="bg_light p-2 h-100 request-table">
      <div className="bg-white border m-4 table-content">
        <div className="row align-items-center pt-3 mb-2">
          <div className="col ms-3">
            <TextField
              id="outlined-basic"
              label="Search By Phone Number .."
              variant="outlined"
              value={patientPhone}
              onChange={(e) => getRequestsByPhone(e)}
              className="float-start mt-4 me-3"
            />
            <div className="float-start me-3 mt-4">
              <Box sx={{ minWidth: 200 }}>
                <FormControl className="w-100">
                  <InputLabel id="demo-simple-select-label">Status</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedStatus}
                    label="Status"
                    onChange={handleChange}
                  >
                    {allRequestStatus.map((statu, index) => {
                      return (
                        <MenuItem key={index} value={statu.id}>
                          {statu.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Box>
            </div>

            <div className="searchDate float-start mt-4">
              <DateRangePicker
                startDate={startDate}
                endDate={endDate}
                onStartDateChange={setStartDate}
                onEndDateChange={setEndDate}
                minimumLength={1}
                format="dd MMM yyyy"
                locale={enGB}
              >
                {({ startDateInputProps, endDateInputProps, focus }) => (
                  <div className="date-range">
                    <input
                      className={
                        "input" + (focus === START_DATE ? " -focused" : "")
                      }
                      {...startDateInputProps}
                      placeholder="Start date"
                    />
                    <i className="fa-solid fa-angle-right text-secondary mx-2"></i>{" "}
                    <input
                      className={
                        "input" + (focus === END_DATE ? " -focused" : "")
                      }
                      {...endDateInputProps}
                      placeholder="End date"
                    />
                  </div>
                )}
              </DateRangePicker>
            </div>

            {/* <div className="float-start filters-label ms-2">
              <div
                className="float-start mt-5 px-4 py-1 mx-2 text-center filters filter-today text-bold"
                onClick={getTodayRequests}
              >
                Today
              </div>
              <div
                className="float-start mt-5 px-4 py-1 mx-2 text-center filters filter-pending"
                onClick={getPendingRequests}
              >
                Pending{" "}
                {selectedStatus === 2 ? (
                  <i className="fa-solid fa-check"></i>
                ) : null}
              </div>
              <div
                className="float-start mt-5 px-4 py-1 mx-2 text-center filters filter-returned"
                onClick={getReturnedRequests}
              >
                Returned{" "}
                {selectedStatus === 4 ? (
                  <i className="fa-solid fa-check"></i>
                ) : null}
              </div>
            </div> */}
            <div className="float-end mx-3" style={{ marginTop: "40px" }}>
              <Button variant="outlined" color="error" onClick={clearAllFilter}>
                Clear Filters
              </Button>
            </div>
          </div>
        </div>
        <TableContainer
          className="table-container"
          sx={{ marginTop: 4 }}
          component={Paper}
        >
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead sx={{ backgroundColor: "#dbdce0" }}>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Patient Name</TableCell>
                <TableCell>Patient Mobile</TableCell>
                <TableCell>Doctor Name</TableCell>
                <TableCell>Service</TableCell>
                <TableCell>Status</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData.results.length > 0 ? (
                tableData.results.map((request) => (
                  <TableRow key={request.id}>
                    <TableCell>
                      <Link
                        to={
                          request.service.type.id === 2
                            ? `/request_3D/${request.id}`
                            : request.service.type.id === 3
                            ? `/request_360/${request.id}`
                            : null
                        }
                      >
                        {request.id}
                      </Link>
                    </TableCell>
                    <TableCell>
                      {new Date(request.created_at).toLocaleString()}
                    </TableCell>
                    <TableCell>{request.patient.fullname}</TableCell>
                    <TableCell>{request.patient.mobile}</TableCell>
                    <TableCell>{request.doctor?.fullname}</TableCell>
                    <TableCell>
                      {`${request.service.type.name}   ${
                        request.service.name
                          ? `( ${request.service.name} )`
                          : ""
                      }`}
                    </TableCell>
                    <TableCell>
                      <Box
                        style={getStyle(request.statusView)}
                        sx={{ minWidth: 120, border: 0 }}
                      >
                        {request.statusView}
                      </Box>
                    </TableCell>
                    <TableCell>
                      <div>
                        <img
                          src={
                            request.note
                              ? "/images/requestTracking/note_activated.svg"
                              : "/images/requestTracking/note_deactivated.svg"
                          }
                          alt="img"
                          className="me-3"
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={10} className="text-center">
                    Not Available Requests
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          {tableData.results.length > 0 ? (
            <div
              className="paginate-box d-flex justify-content-end p-2"
              style={{ backgroundColor: "#E1E1E1" }}
            >
              <Box sx={{ minWidth: 50 }}>
                <FormControl className="w-100">
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={pageSize}
                    label=""
                    onChange={(e) => setPageSize(e.target.value)}
                  >
                    <MenuItem value={5}>5</MenuItem>
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={15}>15</MenuItem>
                    <MenuItem value={20}>20</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <button
                disabled={!tableData.previous}
                className="m-2 border-0 bg-transparent"
                onClick={() => handlePageChange(1)}
              >
                <Tooltip title={`First Page: 1`}>
                  <i className="	fas fa-angle-double-left	"></i>
                </Tooltip>
              </button>
              <button
                onClick={() => handlePageChange(tableData.page_number - 1)}
                disabled={!tableData.previous}
                className="m-2 mx-3 border-0 bg-transparent"
              >
                <Tooltip title={`Previous Page: ${tableData.page_number - 1}`}>
                  <i className="fas fa-chevron-left"></i>
                </Tooltip>
              </button>
              <p className="m-2 mt-3">{`${tableData.from}-${tableData.to}  of  ${tableData.count}`}</p>

              <button
                className="m-2 mx-3 border-0 bg-transparent"
                disabled={!tableData.next}
                onClick={() => handlePageChange(tableData.page_number + 1)}
              >
                <Tooltip title={`Next Page: ${tableData.page_number + 1}`}>
                  <i className="fas fa-chevron-right"></i>
                </Tooltip>
              </button>
              <button
                className="m-2 border-0 bg-transparent"
                disabled={!tableData.next}
                onClick={() => handlePageChange(tableData.total_pages)}
              >
                <Tooltip title={`Last Page: ${tableData.total_pages}`}>
                  <i className="	fas fa-angle-double-right	"></i>
                </Tooltip>
              </button>
            </div>
          ) : (
            ""
          )}
        </TableContainer>
      </div>
      <CustomToastify bgColor={success ? "green" : "red"} duration={false} />
    </div>
  );
};

export default RequestTrackTable;
