import React, { useEffect, useState } from "react";
import Styles from "../home.module.css";

const CoverPatient = ({
  request,
  setShowComponent,
  showComponent,
  flip = false,
}) => {
  const getStyle = () => {
    let style = {};
    if (request.patient) style.backgroundColor = "#DAE8FC";
    else style.backgroundColor = "white";
    return style;
  };

  return (
    <div
      className={`${flip ? "" : "col-5 m-3"}  border rounded text-center py-3 ${
        Styles.patient_section
      }`}
      style={getStyle()}
    >
      <img
        onClick={() => setShowComponent(!showComponent)}
        src="/images/global/nurse_patient.svg"
        className={`my-3 w-30 cursor-pointer`}
        alt="nurse_patient"
      />

      <h6>{request.patientName}</h6>
    </div>
  );
};

export default CoverPatient;
