import React from "react";
import Styles from "../home.module.css";
import SearchPatient from "./SearchPatient";
import SectionSearch from "./SectionSearch";

const ShowPatient = ({
  request,
  setShowComponent,
  showComponent,
  setRequest,
  flip = false,
}) => {
  return (
    <div
      className={`${flip ? "" : "mt-3"} bg-white border rounded overflow-auto ${
        Styles.patient_section
      }`}
    >
      <div className="border-bottom">
        <img
          src="/images/global/nurse_patient.svg"
          className="my-1 w_15 cursor-pointer"
          alt="nurse_patient"
          onClick={() => setShowComponent(!showComponent)}
        />
      </div>
      <SectionSearch
        request={request}
        setShowComponent={setShowComponent}
        showComponent={showComponent}
        setRequest={setRequest}
      />
    </div>
  );
};

export default ShowPatient;
