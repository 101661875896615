import axios from "axios";

const API_URL = "https://py.qubx3d.com/api/v1/";

const login = async (userData) => {
  try {
    const response = await axios.post(API_URL + "branch/login", userData);
    return response.data;
  } catch ({ response }) {
    return response.data;
  }
};

//Logout => remove user from localStorge
const logout = () => {
  localStorage.removeItem("user");
};

const authService = {
  login,
  logout,
};

export default authService;
