import React from "react";
import { Link } from "react-router-dom";
import styles from "./footer.module.css";
const Footer = () => {
  return (
    <footer
      className={`bg_dark fn_light w-100 footer-box text-center ${styles.footer}`}
      // bg_dark fn_light w-100 footer-box text-center ${styles.footer}
    >
      <p className=" mt-4 py-4 mb-0">
        Copyright &copy;{" "}
        <strong>
          <a
            href="https://qubxview.com/"
            target="_blank"
            className="fn_light h5"
          >
            QUBX
          </a>
        </strong>{" "}
        {new Date().getFullYear()} . All Rights Reserved. Developed Powered By{" "}
        <a href="https://teqneia.com/" target="_blank">
          <img
            src="/images/footer/teqneia_logo.svg"
            alt="teqneia logo"
            style={{ width: "75px" }}
          />
        </a>
      </p>
    </footer>
  );
};

export default Footer;
