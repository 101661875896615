import React, { useState } from "react";
import ShowPatient from "./ShowPatient";
import CoverPatient from "./CoverPatient";
import Flippy, { FrontSide, BackSide } from "react-flippy";
import AddPatientForm from "./AddPatientForm";
import SearchPatient from "./SearchPatient";
import "./patient-style.css";

const Patient = ({ request, setRequest }) => {
  const [showComponent, setShowComponent] = useState(true);

  return showComponent ? (
    <Flippy
      flipOnHover={true} // default false
      flipOnClick={false} // default false
      flipDirection="horizontal" // horizontal or vertical
      // ref={(r) => (flippy = r)} // to use toggle method like this.flippy.toggle()

      style={{
        // width: "45%",
        // margin: "0rem 0.3rem",
        height: "auto",
      }} /// these are optional style, it is not necessary
    >
      <FrontSide
        style={{
          backgroundColor: "transparent !important",
          boxShadow: "none !important",
        }}
      >
        <CoverPatient
          flip={true}
          request={request}
          setShowComponent={setShowComponent}
          showComponent={showComponent}
        />
      </FrontSide>
      <BackSide
        style={{
          backgroundColor: "white !important",
          boxShadow: "none !important",
        }}
      >
        {request.patient ? (
          <div
            className={`bg-white border rounded overflow-auto p-3 w-100 h-100`}
          >
            <div className="border-bottom">
              <img
                src="/images/global/nurse_patient.svg"
                className="my-1 w_15 cursor-pointer"
                alt="nurse_patient"
                onClick={() => setShowComponent(!showComponent)}
              />
            </div>
            <AddPatientForm
              request={request}
              setShowComponent={setShowComponent}
              showComponent={showComponent}
              setRequest={setRequest}
            />
          </div>
        ) : (
          <ShowPatient
            flip={true}
            request={request}
            setShowComponent={setShowComponent}
            showComponent={showComponent}
            setRequest={setRequest}
          />
        )}
      </BackSide>
    </Flippy>
  ) : (
    <ShowPatient
      request={request}
      setRequest={setRequest}
      setShowComponent={setShowComponent}
      showComponent={showComponent}
    />
  );
};

export default Patient;
