import React from "react";
import Footer from "../footer/Footer";
import { useNavigate } from "react-router-dom";
import Header from "../header/Header";
import RequestTrackTable from "./RequestTrackTable";

const RequestTracking = () => {
  const navigate = useNavigate();

  if (!JSON.parse(localStorage.getItem("user"))) {
    navigate("/login");
  }
  return (
    <>
      <Header />

      <RequestTrackTable />
    </>
  );
};

export default RequestTracking;
