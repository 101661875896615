import React, { useState, useEffect, useRef } from "react";
import Styles from "../home.module.css";
import axios from "axios";
import WarningNotFound from "./WarningNotFound";
import TextField from "@mui/material/TextField";
import CustomToastify, { toastMes } from "../../../globalConf/notification";
import { httpNonToken, endpoints } from "../../../globalConf/http-non-token";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DoctorForm = ({
  setRequest,
  request,
  showComponent,
  setShowComponent,
  imgRef,
  flag,
  setFlag,
  flip = false,
}) => {
  const [doctors, setDoctor] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [doctorId, setDoctorId] = useState("");
  const [showForm, setShowForm] = useState(true);
  const [fullname, setFullName] = useState();
  const [mobile, setMobile] = useState("");
  const [randomPhoneNum, setRandomPhoneNum] = useState(true);
  const [success, setSuccess] = useState(false);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    (async () => {
      if (request.doctor) {
        try {
          const userRes = await httpNonToken.get(
            `${endpoints.user}${request.doctor}`
          );
          setFullName(userRes.data.fullname);
          setMobile(userRes.data.mobile);
        } catch ({ response }) {
          setSuccess(false);
          toastMes.error(response.data.message);
        }
      } else {
        setFullName(request.searchDoctor);
      }
    })();
  }, []);

  const createDoctorPhoneNumber = async () => {
    setMobile(
      "018" +
        new Date()
          .getTime()
          .toString()
          .substr(new Date().getTime().toString().length - 8)
    );
    setRandomPhoneNum(!randomPhoneNum);
  };

  const createDoctor = async () => {
    const fullnameRegax = /^[a-zA-Z0-9]+([_ -]?[a-zA-Z0-9])*$/;
    const mobileRegax = /^01[0-2,5,8]{1}[0-9]{8}$/;

    if (!fullnameRegax.test(fullname) || !fullname) {
      setSuccess(false);
      toastMes.error(`Not Valid Doctor-Name`);
      return;
    }

    if (!mobile) {
      handleClickOpen();
      return;
    }

    if (!mobileRegax.test(mobile)) {
      setSuccess(false);
      toastMes.error(`Not Valid Doctor Phone Number`);
      return;
    }

    try {
      if (request.doctor) {
        const updateDoctorRes = await httpNonToken.put(
          `${endpoints.updateUser}/${request.doctor}/`,
          { mobile, fullname }
        );
        setSuccess(true);
        toastMes.success("Doctor Updated Successfully");
        setDoctorId(updateDoctorRes.data.id);
        setRequest({
          doctor: updateDoctorRes.data.id,
          doctorName: updateDoctorRes.data.fullname,
          doctorRegister: true,
        });
        setDoctor([]);
        setShowComponent(!showComponent);
      } else {
        const createDoctorRes = await httpNonToken.post(
          `${endpoints.createDoctor}`,
          { user: { mobile, fullname } }
        );
        if (createDoctorRes.status === 201) {
          setSuccess(true);
          toastMes.success("Doctor Created Successfully");
          setDoctorId(createDoctorRes.data.user.id);
          setRequest({
            doctor: createDoctorRes.data.user.id,
            doctorName: createDoctorRes.data.user.fullname,
          });
          setDoctor([]);
          setShowComponent(!showComponent);
          // imgRef.current.click();
          // setFlag(!flag);
        } else {
          setSuccess(false);
          toastMes.success("something went wrong ...");
        }
      }
    } catch ({ response }) {
      for (const key in response.data) {
        if (key === "user") {
          for (const inKey in response.data[key]) {
            setSuccess(false);
            toastMes.error(`${key}-${inKey}: ${response.data[key][inKey]}`);
          }
        } else {
          setSuccess(false);
          toastMes.error(`${key}: ${response.data[key]}`);
        }
      }
    }
  };

  return (
    <div
      className={
        flip
          ? `bg-white border rounded p-2 w-100 ${Styles.doctor_section}`
          : null
      }
    >
      {flip ? (
        <div className="border-bottom">
          <img
            src="/images/global/medical_doctor.svg"
            className="my-1 w_15"
            alt="doctor"
            onClick={() => setShowComponent(!showComponent)}
          />
        </div>
      ) : null}
      <div className="doctor-form  px-3">
        <div className="mt-3">
          <TextField
            InputLabelProps={{ shrink: true }}
            className="w-100"
            size="small"
            id="doctor-fullname"
            label="Doctor Full Name"
            variant="outlined"
            value={fullname}
            onChange={(e) => setFullName(e.target.value)}
          />
        </div>
        <div className="mt-2">
          <TextField
            className="w-100"
            size="small"
            id="outlined-basic"
            label="Doctor Phone"
            variant="outlined"
            value={mobile}
            onChange={(e) => setMobile(e.target.value)}
          />
        </div>
        {request.doctorActive ? null : (
          <div className="d-flex justify-content-end mt-3">
            <Button onClick={createDoctor} variant="contained" size="small">
              {request.doctor ? "Update" : "Save"}
            </Button>
          </div>
        )}
      </div>

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        {randomPhoneNum ? (
          <>
            {" "}
            <DialogTitle>Doctor Number Missing</DialogTitle>
            <DialogContent>
              <div>
                <i className="fas fa-exclamation-triangle fa-3x text-danger text-center d-block my-2"></i>
              </div>
              <DialogContentText
                id="alert-dialog-slide-description"
                className="text-center"
              >
                Doctor Phone Number is required for submission, but if it is
                unavailable now we can create random idle number, Until it is
                available, you can modify it.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Close</Button>
              <Button onClick={createDoctorPhoneNumber}>Proceed Anyway</Button>
            </DialogActions>
          </>
        ) : (
          <>
            <DialogTitle>Doctor Number Created</DialogTitle>
            <DialogContent>
              <div>
                <i className="fas fa-check-circle text-success text-center d-block fa-3x my-2"></i>
              </div>
              <DialogContentText id="alert-dialog-slide-description text-center m-auto">
                Doctor Full-Name: {fullname}
              </DialogContentText>
              <DialogContentText id="alert-dialog-slide-description text-center m-auto">
                Doctor Phone Number: {mobile}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={createDoctor}>Submit</Button>
            </DialogActions>
          </>
        )}
      </Dialog>

      {/* <CustomToastify bgColor={success ? "green" : "red"} duration={8000} /> */}
    </div>
  );
};

export default DoctorForm;
