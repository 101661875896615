import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Styles from "./home.module.css";

const SelectPrint = ({ request, setRequest }) => {
  const [printSection, setPrintSection] = useState(true);
  const [printService, setPrintService] = useState([]);
  const [selectedService, setSelectedService] = useState(request.service);
  useEffect(() => {
    if (request.submit) {
      setSelectedService("");
    }
    fetch("https://py.qubx3d.com/api/v1/service?type__id=2")
      .then((response) => response.json())
      .then((data) => setPrintService(data.results));
    setSelectedService(request.service);
  }, [request.submit]);

  const handleChange = (e) => {
    setSelectedService(parseFloat(e.target.id));
    setRequest({
      service: parseFloat(e.target.id),
      serviceName: e.target.name,
    });
    setPrintSection(!printSection);
  };

  const resetChoice = () => {
    setSelectedService("");
    setRequest({ serviceName: "", service: "" });
    setPrintSection(!printSection);
  };
  const getStyle = () => {
    let style = {};
    if (request.service) style.backgroundColor = "#DAE8FC";
    else style.backgroundColor = "white";
    return style;
  };
  return (
    <>
      {printSection ? (
        <div
          style={getStyle()}
          className={`border mt-3 rounded text-center py-3 ${Styles.printing3d_section}`}
        >
          <img
            src="/images/global/3d-printing.svg"
            className="my-3 w-40"
            alt="doctor"
            onClick={() => setPrintSection(!printSection)}
          />
          <h6>
            {" "}
            {`3D Printing ${
              request.serviceName ? `( ${request.serviceName} )` : ""
            }`}
          </h6>
        </div>
      ) : (
        <div
          className={`bg-white mt-3 border rounded  ${Styles.printing3d_section}`}
          style={{ minHeight: "250px", maxHeight: "250px", overflow: "auto" }}
        >
          <div className="border-bottom">
            <img
              src="/images/global/3d-printing.svg"
              className="my-1 w_15 cursor-pointer"
              alt="doctor"
              onClick={() => setPrintSection(!printSection)}
            />
          </div>
          <div className="mt-2 ms-2 p-1">
            <div className="d-flex justify-content-end">
              <Button
                size="small"
                onClick={resetChoice}
                variant="outlined"
                color="error"
              >
                Clear Service
              </Button>
            </div>
            {printService.map((printing, id) => (
              <div className="form-check  fn_sm fw-bold m-2 ls-1" key={id}>
                <input
                  className={`form-check-input ${Styles.select_printing}`}
                  type="radio"
                  name={printing.name}
                  id={printing.id}
                  checked={selectedService === printing.id}
                  value={selectedService}
                  onChange={(e) => handleChange(e)}
                />
                <label className="form-check-label" htmlFor={printing.id}>
                  {printing.name}
                </label>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default SelectPrint;
