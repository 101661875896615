import React, { useState, useEffect, useRef, createRef } from "react";
import { useDispatch } from "react-redux";
import { addPatient } from "../../../features/patient/patientSlice";
import CustomToastify, { toastMes } from "../../../globalConf/notification";
import { httpNonToken, endpoints } from "../../../globalConf/http-non-token";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import moment from "moment";

const AddPatientForm = ({
  request,
  setShowComponent,
  showComponent,
  setRequest,
}) => {
  const [fullname, setFullname] = useState("");
  const [mobile, setMobile] = useState();
  const [phone, setPhone] = useState(null);
  // request.patientPhone
  const [DOB, setDOB] = useState("");
  const [national_id, setNational_id] = useState("");
  const [success, setSuccess] = useState();
  const phoneRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    let isMounted = true;
    (async () => {
      try {
        if (request.patient) {
          const getPatientRes = await httpNonToken.get(
            `${endpoints.patient}/${request.patient}`
          );
          if (isMounted) {
            setFullname(getPatientRes.data.fullname);
            setDOB(getPatientRes.data.DOB);
            setNational_id(getPatientRes.data.national_id);
            setMobile(getPatientRes.data.mobile);
            setPhone(getPatientRes.data.phone);
          }
        } else {
          setMobile(request.patientPhone);
        }
      } catch ({ response }) {
        setSuccess(false);
        toastMes.error(response.data.message);
      }
    })();
    return () => {
      isMounted = false;
    };
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      fullname,
      mobile,
      phone,
      DOB,
      national_id,
    };
    const fullnameRegax = /^[a-zA-Z0-9]+([_ -]?[a-zA-Z0-9])*$/;
    const mobileRegax = /^01[0-2,5]{1}[0-9]{8}$/;
    const nationalIdRegax =
      /(2|3)[0-9][1-9][0-1][1-9][0-3][1-9](01|02|03|04|11|12|13|14|15|16|17|18|19|21|22|23|24|25|26|27|28|29|31|32|33|34|35|88)\d\d\d\d\d/;
    if (!fullnameRegax.test(fullname)) {
      setSuccess(false);
      toastMes.error(`Not Valid Patient-Name`);
      return;
    }
    // 29905111600077
    if (!mobileRegax.test(mobile)) {
      setSuccess(false);
      toastMes.error(`Not Valid Phone-Number`);
      return;
    }
    if (national_id && !nationalIdRegax.test(national_id)) {
      setSuccess(false);
      toastMes.error(`Not Valid National-Id`);
      return;
    }
    if (moment(DOB).isAfter(new Date())) {
      setSuccess(false);
      toastMes.error(`Not Valid Birth Of Date`);
      return;
    }
    console.log(data);
    if (request.patient) {
      const updatePatientRes = await httpNonToken.put(
        `${endpoints.updatePatient}/${request.patient}`,
        data
      );
      setSuccess(true);
      setRequest({
        patient: updatePatientRes.data.id,
        patientName: updatePatientRes.data.fullname,
        patientPhone: updatePatientRes.data.mobile,
      });
      setShowComponent(!showComponent);
      toastMes.success("Patient Updated Successfully");
    } else {
      let addPatientRes = await dispatch(addPatient(data));
      if (addPatientRes.error) {
        setSuccess(false);
        toastMes.error(`All Fields are required`);
      } else {
        setSuccess(true);
        setRequest({
          patient: addPatientRes.payload.id,
          patientName: addPatientRes.payload.fullname,
          patientPhone: addPatientRes.payload.mobile,
        });
        setShowComponent(!showComponent);
        toastMes.success("Patient Created Successfully");
      }
    }
  };

  return (
    <div className="add-patient  px-3">
      <form>
        <div className="row mt-2">
          <div className="m-0 col-lg-12  mt-2">
            <TextField
              fullWidth
              id="Full-Name"
              label="Patient Full-Name"
              variant="outlined"
              size="small"
              value={fullname}
              onChange={(e) => setFullname(e.target.value)}
            />
          </div>
          <div className="m-0 col-lg-12 mt-2">
            <TextField
              value={mobile}
              InputLabelProps={{ shrink: true }}
              fullWidth
              id="patient-mobile"
              label="Patient Mobile"
              ref={phoneRef}
              size="small"
              onChange={(e) => setMobile(e.target.value)}
            />
          </div>

          <div className="m-0 col-lg-12  mt-3">
            <input
              type="date"
              id="searchDate"
              max={
                new Date(
                  new Date().getTime() - new Date().getTimezoneOffset() * 60000
                )
                  .toISOString()
                  .split("T")[0]
              }
              value={DOB}
              onChange={(e) => setDOB(e.target.value)}
              className="form-control"
              style={{ padding: "7px 11px" }}
            />
          </div>
          <div className="m-0 mt-3 col-lg-12">
            <TextField
              fullWidth
              id="National-ID"
              label="National-ID"
              variant="outlined"
              size="small"
              value={national_id}
              onChange={(e) => setNational_id(e.target.value)}
            />
          </div>
        </div>
        <div className="m-0 col-lg-12 mt-2">
          <TextField
            value={phone}
            InputLabelProps={{ shrink: true }}
            fullWidth
            id="patient-phone"
            label="Secondary Mobile"
            size="small"
            onChange={(e) => setPhone(e.target.value)}
          />
        </div>
        <Button
          onClick={handleSubmit}
          className="mt-2 float-end"
          variant="contained"
          size="small"
        >
          {request.patient ? "Update" : "Save"}
        </Button>
      </form>
      {/* <CustomToastify bgColor={success ? "green" : "red"} duration={8000} /> */}
    </div>
  );
};

export default AddPatientForm;
