import React, { useEffect, useState } from "react";
import Styles from "./selectdData.module.css";
import { AiOutlineCloudServer } from "react-icons/ai";
import { GiCompactDisc } from "react-icons/gi";
import { GiBreakingChain } from "react-icons/gi";
import { httpNonToken, endpoints } from "../../../globalConf/http-non-token";

const SelectData = ({ request, setRequest }) => {
  const [dicones, setdicones] = useState([]);
  const [selectedDicone, setSelectedDicone] = useState("");
  const [check, setCheck] = useState(false);

  let data = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    let isMounted = true;
    (async () => {
      try {
        const diconSourceRes = await httpNonToken.get(
          `${endpoints.diconSource}`
        );
        if (isMounted) {
          setdicones(diconSourceRes.data.results);
          setSelectedDicone(data.user.branch.dicon_source);
          setRequest({ DICON_source: data.user.branch.dicon_source });
        }
      } catch ({ response }) {
        console.log(response.data.message);
      }
    })();
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    if (request.submit) {
      setCheck(false);
      setSelectedDicone(data.user.branch.dicon_source);
      setRequest({ DICON_source: data.user.branch.dicon_source });
    }
  }, [request]);

  const handleSelectedDicone = (e, id) => {
    setSelectedDicone(id);
    setRequest({ DICON_source: id });
  };

  const getStyle = () => {
    let style = {};
    if (check) {
      style.backgroundColor = "#DAE8FC";
      style.color = "#275ff8 ";
    } else {
      style.backgroundColor = "white";
      style.color = "#afafb9";
    }
    return style;
  };

  return (
    <div
      className={`bg-white m-2 p-3 border-primary border_dash rounded text-center `}
    >
      <div className="row">
        {dicones &&
          dicones.length > 0 &&
          dicones.map((dicone) => {
            return (
              <div
                className={`col border m-2 p-0 ${Styles.select_data}`}
                key={dicone.id}
              >
                <input
                  type="radio"
                  id={dicone.name}
                  onChange={(e) => handleSelectedDicone(e, dicone.id)}
                  checked={selectedDicone === dicone.id}
                  value={selectedDicone}
                  className={`d-none form-check-input ${Styles.select_data}`}
                  name="select"
                />
                <label
                  htmlFor={dicone.name}
                  className={`w-100 ${Styles.select_data}`}
                >
                  {dicone.name === "URL" && dicone.id === 4 ? (
                    <GiBreakingChain className="mt-4 mb-3 w-50 h-50" />
                  ) : dicone.name === "Pacs Cloud" && dicone.id === 2 ? (
                    <AiOutlineCloudServer className="mt-4 mb-3 w-50 h-50" />
                  ) : dicone.name === "CD" && dicone.id === 3 ? (
                    <GiCompactDisc className="mt-4 mb-3 w-50 h-50" />
                  ) : (
                    ""
                  )}
                  <h6>{dicone.name}</h6>
                </label>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default SelectData;
