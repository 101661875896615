import React, { useState, useEffect } from "react";
import SearchForm from "./SearchForm";
import CoverDoctor from "./CoverDoctor";
import Flippy, { FrontSide, BackSide } from "react-flippy";
import DoctorForm from "./doctorForm";
import "./doctor-style.css";

const Doctor = ({ request, setRequest }) => {
  const [showComponent, setShowComponent] = useState(true);
  const [flag, setFlag] = useState(false);

  useEffect(() => {
    if (request.patient) {
      setFlag(true);
    }
  }, [request]);

  return showComponent ? (
    <Flippy
      flipOnHover={true} // default false
      flipOnClick={false} // default false
      flipDirection="horizontal" // horizontal or vertical
      // ref={(r) => (flippy = r)} // to use toggle method like this.flippy.toggle()

      style={{
        // width: "45%",
        height: "auto",
      }} /// these are optional style, it is not necessary
    >
      <FrontSide
        style={{
          backgroundColor: "transparent !important",
          boxShadow: "none !important",
        }}
      >
        <CoverDoctor
          flag={flag}
          request={request}
          setRequest={setRequest}
          showComponent={showComponent}
          setShowComponent={setShowComponent}
        />
      </FrontSide>
      <BackSide
        style={{
          backgroundColor: "transparent !important",
          boxShadow: "none !important",
        }}
      >
        {!request.doctor ? (
          <SearchForm
            flip={true}
            flag={flag}
            setFlag={setFlag}
            request={request}
            setRequest={setRequest}
            showComponent={showComponent}
            setShowComponent={setShowComponent}
          />
        ) : (
          <DoctorForm
            flip={true}
            request={request}
            setRequest={setRequest}
            showComponent={showComponent}
            setShowComponent={setShowComponent}
          />
        )}
      </BackSide>
    </Flippy>
  ) : (
    <SearchForm
      flag={flag}
      setFlag={setFlag}
      request={request}
      setRequest={setRequest}
      showComponent={showComponent}
      setShowComponent={setShowComponent}
    />
  );
};

export default Doctor;
