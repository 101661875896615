import React from "react";

const Date = ({ branch }) => {
  let user = JSON.parse(localStorage.getItem("user"));
  return (
    <div className="mt-2 ms-4 fn_main">
      <span> </span>
      {/* <span className="text-uppercase">
        {Object.keys(user).length ? user.fullname : ""},{" "}
      </span>
      <span className="text-uppercase">
        {Object.keys(user).length ? user.branch : ""}{" "}
      </span> */}
    </div>
  );
};

export default Date;
